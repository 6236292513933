const CircledMinusIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15 10C15 10.1989 14.921 10.3897 14.7803 10.5303C14.6397 10.671 14.4489 10.75 14.25 10.75H6.75C6.55109 10.75 6.36033 10.671 6.21967 10.5303C6.07902 10.3897 6 10.1989 6 10C6 9.80109 6.07902 9.61032 6.21967 9.46967C6.36033 9.32902 6.55109 9.25 6.75 9.25H14.25C14.4489 9.25 14.6397 9.32902 14.7803 9.46967C14.921 9.61032 15 9.80109 15 10ZM20.25 10C20.25 11.9284 19.6782 13.8134 18.6068 15.4168C17.5355 17.0202 16.0127 18.2699 14.2312 19.0078C12.4496 19.7458 10.4892 19.9389 8.59787 19.5627C6.70656 19.1865 4.96928 18.2579 3.60571 16.8943C2.24215 15.5307 1.31355 13.7934 0.937348 11.9021C0.561142 10.0108 0.754225 8.05042 1.49218 6.26884C2.23013 4.48726 3.47982 2.96451 5.08319 1.89317C6.68657 0.821828 8.57164 0.25 10.5 0.25C13.085 0.25273 15.5634 1.28084 17.3913 3.10872C19.2192 4.93661 20.2473 7.41498 20.25 10ZM18.75 10C18.75 8.3683 18.2661 6.77325 17.3596 5.41655C16.4531 4.05984 15.1646 3.00242 13.6571 2.37799C12.1497 1.75357 10.4909 1.59019 8.89051 1.90852C7.29017 2.22685 5.82016 3.01259 4.66637 4.16637C3.51259 5.32015 2.72685 6.79016 2.40853 8.3905C2.0902 9.99085 2.25358 11.6496 2.878 13.1571C3.50242 14.6646 4.55984 15.9531 5.91655 16.8596C7.27326 17.7661 8.86831 18.25 10.5 18.25C12.6873 18.2475 14.7843 17.3775 16.3309 15.8309C17.8775 14.2843 18.7475 12.1873 18.75 10Z" />
    </svg>
  )
}

export default CircledMinusIcon
