import type { IconProps } from '../../../typings/props'

const EmailDelivery = ({ className, title }: IconProps) => {
  return (
    <svg
      className={className}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="41px"
      height="27px"
      viewBox="0 0 41 27"
    >
      {title ? <title>{title}</title> : null}
      <path d="M 29.730469 19.472656 L 33.796875 19.472656 L 32.796875 20.492188 C 32.460938 20.835938 32.460938 21.390625 32.796875 21.722656 C 32.964844 21.894531 33.183594 21.980469 33.398438 21.980469 C 33.617188 21.980469 33.832031 21.894531 34 21.722656 L 36.453125 19.214844 C 36.789062 18.871094 36.789062 18.316406 36.453125 17.984375 L 34 15.472656 C 33.664062 15.128906 33.121094 15.140625 32.796875 15.472656 C 32.460938 15.820312 32.460938 16.371094 32.796875 16.703125 L 33.796875 17.726562 L 29.730469 17.726562 C 29.261719 17.726562 28.875 18.117188 28.875 18.597656 C 28.875 19.078125 29.261719 19.472656 29.730469 19.472656 Z M 29.730469 19.472656 " />
      <path d="M 33.867188 10.296875 L 33.867188 0.859375 C 33.867188 0.382812 33.496094 0 33.027344 0 L 0.84375 0 C 0.371094 0 0 0.382812 0 0.859375 L 0 21.96875 C 0 22.449219 0.371094 22.832031 0.84375 22.832031 L 25.714844 22.832031 C 27.144531 25.316406 29.777344 26.988281 32.785156 26.988281 C 37.308594 26.988281 40.988281 23.222656 40.988281 18.597656 C 40.988281 14.355469 37.871094 10.835938 33.855469 10.285156 Z M 32.785156 11.957031 C 36.371094 11.957031 39.28125 14.933594 39.28125 18.597656 C 39.28125 22.265625 36.359375 25.242188 32.785156 25.242188 C 29.203125 25.242188 26.289062 22.265625 26.289062 18.597656 C 26.289062 14.933594 29.203125 11.957031 32.785156 11.957031 Z M 32.183594 10.246094 C 29.660156 10.429688 27.457031 11.796875 26.074219 13.789062 L 22.960938 10.601562 L 32.171875 2.816406 L 32.171875 10.234375 Z M 30.835938 1.722656 L 17.703125 12.816406 C 17.246094 13.210938 16.523438 13.210938 16.054688 12.816406 L 3.019531 1.722656 Z M 1.683594 2.828125 L 10.871094 10.652344 L 1.683594 20.039062 Z M 3.007812 21.121094 L 12.160156 11.757812 L 14.972656 14.15625 C 15.503906 14.613281 16.175781 14.859375 16.875 14.859375 C 17.570312 14.859375 18.234375 14.613281 18.773438 14.15625 L 21.660156 11.722656 L 25.21875 15.363281 C 24.800781 16.394531 24.582031 17.496094 24.582031 18.609375 C 24.582031 19.484375 24.714844 20.332031 24.957031 21.121094 Z M 3.007812 21.121094 " />
    </svg>
  )
}

export default EmailDelivery
