import type { IconProps } from '../../../typings/props'

const PlayIcon = ({ className, title }: IconProps) => {
  return (
    <svg
      className={className}
      width="30"
      height="29"
      viewBox="0 0 30 29"
      fill="currentColor"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      {title ? <title>{title}</title> : null}

      <path
        d="M24.8996 4.82627C22.2551 2.18214 18.7396 0.72583 15 0.72583C11.2604 0.72583 7.74489 2.18214 5.10044 4.82627C2.45631 7.47072 1 10.9863 1 14.7258C1 18.4654 2.45631 21.9809 5.10044 24.6254C7.74489 27.2695 11.2604 28.7258 15 28.7258C18.7396 28.7258 22.2551 27.2695 24.8996 24.6254C27.5437 21.9809 29 18.4654 29 14.7258C29 10.9863 27.5437 7.47072 24.8996 4.82627ZM15 27.632C7.88333 27.632 2.09387 21.8425 2.09387 14.7258C2.09387 7.60916 7.88333 1.8197 15 1.8197C22.1167 1.8197 27.9061 7.60916 27.9061 14.7258C27.9061 21.8425 22.1167 27.632 15 27.632Z"
        strokeWidth="0.3"
      />
      <path
        d="M22.4984 14.253L11.3298 7.80496C11.1606 7.70727 10.9521 7.70727 10.7829 7.80496C10.6136 7.90265 10.5094 8.08309 10.5094 8.27847V21.1746C10.5094 21.37 10.6136 21.5505 10.7829 21.6482C10.8675 21.697 10.9618 21.7216 11.0563 21.7216C11.1509 21.7216 11.2452 21.697 11.3298 21.6482L22.4984 15.2001C22.6676 15.1024 22.7718 14.9219 22.7718 14.7266C22.7718 14.5312 22.6676 14.3507 22.4984 14.253ZM11.6033 20.2276V9.22549L21.131 14.7266L11.6033 20.2276Z"
        strokeWidth="0.3"
      />
    </svg>
  )
}

export default PlayIcon
