const EnvelopeIcon = () => {
  return (
    <svg
      width="24"
      height="16"
      viewBox="0 0 24 16"
      stroke="currentColor"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.8243 1H1V15H22.8243V1Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.665 1.21826L12.7969 9.36994C12.538 9.58508 12.2119 9.70285 11.8753 9.70285C11.5386 9.70285 11.2126 9.58508 10.9536 9.36994L1.15924 1.21826"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.15924 14.9323L8.48218 7.6123"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6651 14.9323L15.3451 7.6123"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default EnvelopeIcon
