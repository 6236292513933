import type { IconProps } from '../../../typings/props'

const NationalIcon = ({ className, title }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="36"
      height="26"
      fill="currentColor"
      viewBox="0 0 36 26"
    >
      {title ? <title>{title}</title> : null}
      <path d="m21.173 0-12 .165a1.059 1.059 0 0 0-.835.422l-.557.751-.03.009L6.992.54A1.059 1.059 0 0 0 6.22.205L3.773.219a1.059 1.059 0 0 0-.993.712L.058 8.726a1.06 1.06 0 0 0 .032.773l3.065 6.933c.071.16.181.3.32.407L7.83 20.2c.188.145.42.222.658.22l5.214-.048 2.798 4.424a1.06 1.06 0 0 0 .89.492h1.594a1.059 1.059 0 0 0 .978-.647l1.822-4.387h1.305c.28.002.55-.107.75-.304l.938-.939h1.758l3.824 4.198a1.06 1.06 0 0 0 1.595-.03l2.07-2.495a1.059 1.059 0 0 0-.062-1.423l-2.073-2.101c.02-.26-.062-.83 1.032-1.173a1.06 1.06 0 0 0 .743-1.017V9.514l1.87-1.258A1.059 1.059 0 0 0 36 7.38V2.033a1.06 1.06 0 0 0-1.64-.883l-5.934 3.878h-2.281l-1.01-2.118a1.059 1.059 0 0 0-.953-.602h-1.938V1.065a1.057 1.057 0 0 0-.661-.986 1.06 1.06 0 0 0-.41-.079Zm-1.049 2.134V3.37a1.06 1.06 0 0 0 1.057 1.057h2.33l1.015 2.118a1.06 1.06 0 0 0 .953.602h3.26c.208.001.412-.058.587-.172l4.556-2.978v2.82l-1.867 1.26a1.06 1.06 0 0 0-.47.875v5.644c-1.519 1.036-2.008 2.697-2.008 2.697a1.059 1.059 0 0 0 .304.805l1.94 1.97-.68.82-3.316-3.65a1.06 1.06 0 0 0-.782-.344h-2.666a1.06 1.06 0 0 0-.748.304l-.94.94h-1.577a1.06 1.06 0 0 0-.979.655L18.28 23.17h-.304l-2.799-4.432a1.058 1.058 0 0 0-.908-.492l-5.43.047-3.856-2.963-2.79-6.3 2.337-6.7 1.235-.008.939 1.002a1.06 1.06 0 0 0 1.156.256l.97-.383a1.06 1.06 0 0 0 .461-.351l.43-.571 10.403-.14Z" />
    </svg>
  )
}

export default NationalIcon
