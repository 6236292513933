import { colors } from '../../../../constants/colors'

const EPrintSimple = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M22.75 45.51C10.21 45.51 0 35.3 0 22.75C0 10.2 10.21 0 22.75 0C35.29 0 45.51 10.21 45.51 22.75C45.51 35.29 35.3 45.51 22.75 45.51ZM22.75 3.5C12.14 3.5 3.5 12.14 3.5 22.75C3.5 33.36 12.14 42.01 22.75 42.01C33.36 42.01 42.01 33.37 42.01 22.75C42.01 12.13 33.37 3.5 22.75 3.5Z"
          fill={`var(${colors.badgeTurquoiseLight.token})`}
        />
        <path
          d="M33.35 33.38C30.52 36.21 26.77 37.77 22.77 37.77C14.49 37.78 7.74001 31.06 7.73001 22.78C7.73001 18.76 9.28001 14.97 12.14 12.13C14.97 9.3 18.74 7.74 22.74 7.74C26.75 7.73 30.52 9.29 33.37 12.12C36.22 14.95 37.78 18.72 37.79 22.73C37.79 23.2 37.76 23.7 37.7 24.25L10.84 24.3L10.92 24.77C11.9 30.59 16.89 34.81 22.79 34.79C25.99 34.79 28.98 33.53 31.25 31.28C32.14 30.39 32.88 29.38 33.45 28.27L36.38 29.14C35.62 30.72 34.61 32.15 33.36 33.38H33.35ZM14.26 14.25C12.46 16.05 11.31 18.31 10.89 20.8L10.81 21.27L34.66 21.23L34.58 20.76C33.6 14.94 28.61 10.72 22.71 10.74C19.53 10.74 16.52 11.99 14.25 14.25H14.26Z"
          fill={`var(${colors.badgeTurquoiseLight.token})`}
        />
      </g>
    </svg>
  )
}

export default EPrintSimple
