import React from 'react'

const EventsIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.6769 0.830769C12.6769 0.769231 12.6769 0.707692 12.6769 0.646154C12.6769 0.338462 12.4 0.0307692 12.0615 0.0307692C11.7231 0.0307692 11.4462 0.307692 11.4462 0.646154C11.4462 0.707692 11.4462 0.769231 11.4462 0.830769H3.81538V0.8C3.81538 0.738462 3.81538 0.676923 3.81538 0.615385C3.81538 0.307692 3.53846 0 3.2 0C2.86154 0 2.58462 0.276923 2.58462 0.615385C2.58462 0.707693 2.58462 0.769231 2.58462 0.861538C1.16923 0.861538 0 2.03077 0 3.44615V13.4154C0 14.8308 1.16923 16 2.58462 16H12.5538C13.9692 16 15.1385 14.8308 15.1385 13.4154V3.41538C15.1385 2.03077 14.0308 0.892308 12.6769 0.830769ZM2.58462 2.06154C2.58462 2.09231 2.58462 2.15385 2.58462 2.18462C2.58462 2.49231 2.86154 2.8 3.2 2.8C3.53846 2.8 3.81538 2.52308 3.81538 2.18462C3.81538 2.15385 3.81538 2.09231 3.81538 2.06154H11.4462C11.4462 2.12308 11.4462 2.18462 11.4462 2.24615C11.4462 2.55385 11.7231 2.86154 12.0615 2.86154C12.4 2.86154 12.6769 2.58462 12.6769 2.24615C12.6769 2.18462 12.6769 2.12308 12.6769 2.06154C13.3846 2.12308 13.9385 2.70769 13.9385 3.41538V5.13846H1.23077V3.41538C1.23077 2.64615 1.84615 2.06154 2.58462 2.06154ZM12.5538 14.7385H2.58462C1.84615 14.7385 1.23077 14.1231 1.23077 13.3846V6.36923H13.9385V13.3846C13.9077 14.1231 13.2923 14.7385 12.5538 14.7385Z" />
      <path d="M6.33846 8.70752H3.2C2.52308 8.70752 2 9.26136 2 9.90752V11.1075C2 11.7844 2.55385 12.3075 3.2 12.3075H6.33846C7.01539 12.3075 7.53846 11.7537 7.53846 11.1075V9.90752C7.53846 9.26136 7.01539 8.70752 6.33846 8.70752ZM6.30769 11.0767H3.23077V9.93829H6.33846V11.0767H6.30769Z" />
      <path d="M12.7383 8.70752C11.7229 8.70752 10.7075 8.70752 9.69215 8.70752C9.5383 8.70752 9.41523 8.70752 9.26138 8.70752C8.95369 8.70752 8.646 8.98444 8.646 9.3229C8.646 9.66137 8.92292 9.93829 9.26138 9.93829C10.2768 9.93829 11.2922 9.93829 12.3075 9.93829C12.4614 9.93829 12.5845 9.93829 12.7383 9.93829C13.046 9.93829 13.3537 9.66137 13.3537 9.3229C13.3229 8.98444 13.0768 8.70752 12.7383 8.70752Z" />
      <path d="M12.7383 11.0771C11.7229 11.0771 10.7075 11.0771 9.69215 11.0771C9.5383 11.0771 9.41523 11.0771 9.26138 11.0771C8.95369 11.0771 8.646 11.3541 8.646 11.6925C8.646 12.031 8.92292 12.3079 9.26138 12.3079C10.2768 12.3079 11.2922 12.3079 12.3075 12.3079C12.4614 12.3079 12.5845 12.3079 12.7383 12.3079C13.046 12.3079 13.3537 12.031 13.3537 11.6925C13.3229 11.3848 13.0768 11.0771 12.7383 11.0771Z" />
    </svg>
  )
}

export default EventsIcon
