const ChevronLeft = () => {
  return (
    <svg
      width="10"
      height="18"
      viewBox="0 0 10 18"
      stroke="currentColor"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 16.5L1 9L8.5 1.5"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ChevronLeft
