export const CUSTOM_DATA_APP_ID = 'folderimprinting'
export const CUSTOM_DATA_FIELD = 'customizations'

export const FOLDER_IMPRINTING_IMAGES: Record<string, string> = {
  fallback:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/b9149075-6776-4384-92a8-6a3b5b8fffb0___21d55fdf4eb564d439be0e73fc333cd5.jpg',
  958082:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/35fa3537-0878-4646-b21d-ffa12c43bcb4___18654ed32d73f59c35799d7ed5fabe6a.jpg',
  958090:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/68f21bba-4ffd-46ee-bec0-3433340b3a3d___bd7073932d556dc92c0dc4dbe2bf1d57.jpg',
  958108:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/74a6c176-bef3-4fe0-a03d-830e73dbd72e___29003d9aaf03a9c1dc01bac43a04c906.jpg',
  958116:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/dd36c0d5-18df-4f56-9a2e-ad5e93825a2d___fc3d980b363601f2203697cf919cb678.jpg',
  958124:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/331e64ad-9a9d-4187-9a2d-86edf60b3731___ff1ec53c0b91811189776e3f0a93816e.jpg',
  958132:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/b4af434e-52d0-4f66-8e7c-34e83ab8bb97___2acaa1837f270fb3d487f71c9fe1ff13.jpg',
  958140:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/f1879f19-138e-4ed4-9a6b-8814d82a6036___089d133dec2e350def9c50ea0608ae0e.jpg',
  958157:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/1f71e0c3-af37-45e7-89d5-30d3c509f882___2cb2b368a963d6a5cbf90b8e17160adf.jpg',
  958165:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/3a6a9428-82e8-435d-b464-d3f60acb5daa___c373aa653d907411ffce2db4ad6854ed.jpg',
  958173:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/77890d2d-759b-4d0a-9c73-068d9fa35cf5___9fc314b9f052a9af87046e5949947723.jpg',
  958181:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/578d3ca3-675d-4c5e-bc36-8d721a18f0f3___ff204251c33d70aef3f839042de6cc4e.jpg',
  958199:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/f5a03428-b8bb-4613-89eb-342c8016aaf4___5e86b3e55c7e6277d62bf45eb567705c.jpg',
  958207:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/bd6aa2d2-ac3a-4b85-bcbe-022b256701e9___093f82d34b653db71960cde563b850dd.jpg',
  958215:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/d954e9ce-1556-412a-baa8-02de343cae76___bd97be3e88f52416a14661a064d550a4.jpg',
  958223:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/4adeebdf-e09a-429d-aded-caec4d37c74a___1251da2dfc1560f2f3986f50a1f18a1a.jpg',
  958231:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/b6b1c81d-7f0c-41de-b8a0-a0db52990c84___1e6d2fc6ec2bbf50bc94edda738db665.jpg',
  958249:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/2d71e459-3036-49f9-ac02-36e5aae57c76___d07966e990f924b5df8a4f5a2f04d954.jpg',
  1309475:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/2bb4cd4d-7f9b-44e9-ab9e-6ad840ae7d3b___71dd450713efdf561c1e7270e1900358.jpg',
  1309483:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/5e0bbbfa-bdd6-4002-97bd-3d87e0e7ebf7___9b6dfe5de901ee2c6664931c30c1bc94.jpg',
  1309491:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/36dce31f-416c-49f2-ad2a-f61352fa8fb8___04d5876ec4dfaa944881e6adbee352bb.jpg',
  1309509:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/25ec0af6-07d1-4aab-93c0-a3fdbb814a6e___8ae171b2aac85c5ab30380211948f0d0.jpg',
  2033447:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/b9149075-6776-4384-92a8-6a3b5b8fffb0___21d55fdf4eb564d439be0e73fc333cd5.jpg',
  2047231:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/66477515-ac8e-4963-ad59-d1df2ba49a6b___7d60d1b51461582273a52127cd13d949.jpg',
  2047249:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/65150806-9e05-4919-bd1b-6f1064469cd8___edca1953191ea01df10eaa7852209962.jpg',
  2047256:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/cf638ed9-ae39-4f0c-980f-84adbe7a92ba___063535f9c092a52946a30df78c87a924.jpg',
  2047264:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/6db8f41b-7a81-44ff-9ac5-1af6dc0db58c___7591b94755e59b500ba91292470022c7.jpg',
  10044831:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/0d3aedf4-9746-408b-a728-0e7e47bcb608___db9ca1eb3e6aee2ea19d619827c6ccf9.jpg',
  10044835:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/3c28ef9a-9822-4591-b019-4004e3581376___5010ed6d66c0d9d5e88131500af5a793.jpg',
  10044836:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/7821374f-c0bf-4028-9c0a-01f845e74df2___91a05124011be4e4cdc5dfa1f82c20c9.jpg',
  10044837:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/9b0c9225-60d3-428d-99b3-8c83bf76bcc6___f20bdcec4bb0c1e69f428b417cf62b4b.jpg',
  10044838:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/c6074a0a-18c2-4fe6-9975-e6adcdefd79e___fdb383118484cdc5ad0937d793533a02.jpg',
  10044840:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/5909578b-869e-474d-864c-5571fa25a673___5010bfad31cf700d75e4760d900c990f.jpg',
  10044841:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/06147dbf-9d9b-4b7c-aba5-9010783a69be___6aa4596c3a5f2a0dd1674ac10c304e92.jpg',
  10044842:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/65d3ab04-e9a3-4fa2-840a-b6854461327f___0853ae0045b053054fe023cae93e4ee2.jpg',
  10044843:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/bd3f869b-ef34-41c8-9676-f8c4322544d6___6a80d6bd22113a90e48d3ad452c588aa.jpg',
  10913548:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/d21e19f1-885c-4e45-99ea-3799d35b3948___093f82d34b653db71960cde563b850dd.jpg',
  10044844:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/9bfac686-8c2e-48cb-af07-4809e3f3abc2___9a329fd06cc24f79496792870024b55c.jpg',
  10913551:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/90fa9aa1-a4ca-40f4-b36a-968fd32be293___2acaa1837f270fb3d487f71c9fe1ff13.jpg',
  10913553:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/f220f3f0-cc46-418f-8529-7b84a074625e___5e86b3e55c7e6277d62bf45eb567705c.jpg',
  10913554:
    'https://jwpepper.vtexassets.com/assets/vtex.file-manager-graphql/images/ce7eb955-8c41-46db-b037-ec0b86322d06___9fc314b9f052a9af87046e5949947723.jpg',
}
