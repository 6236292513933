import type { IconProps } from '../../../typings/props'

const RulerIcon = ({ className, title }: IconProps) => {
  return (
    <svg
      className={className}
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      {title ? <title>{title}</title> : null}

      <path d="M0.412594 9.34661C0.223064 9.45609 0.0847761 9.63636 0.0281422 9.84778C-0.0284916 10.0592 0.00116639 10.2845 0.110594 10.474L3.77859 16.8276C3.8328 16.9215 3.90497 17.0038 3.99097 17.0697C4.07698 17.1357 4.17514 17.1841 4.27984 17.2122C4.38455 17.2402 4.49376 17.2473 4.60123 17.2332C4.7087 17.219 4.81233 17.1838 4.90619 17.1296L19.5874 8.65361C19.6813 8.59941 19.7635 8.52724 19.8295 8.44124C19.8955 8.35523 19.9439 8.25707 19.9719 8.15236C20 8.04766 20.0071 7.93845 19.993 7.83098C19.9788 7.72351 19.9436 7.61988 19.8894 7.52601L16.2214 1.17241C16.1672 1.07854 16.095 0.996265 16.009 0.930284C15.923 0.864303 15.8249 0.81591 15.7201 0.787866C15.6154 0.759822 15.5062 0.752678 15.3988 0.766842C15.2913 0.781005 15.1877 0.816199 15.0938 0.870413L0.412594 9.34661ZM1.95279 10.3634L15.2044 2.71261L18.0472 7.63661L17.0922 8.18801L16.0268 6.34221L15.312 6.75501L16.3774 8.60061L15.2844 9.23181L14.2188 7.38601L13.504 7.79881L14.5696 9.64441L13.4778 10.2748L12.4122 8.42901L11.6974 8.84181L12.763 10.6874L11.67 11.3186L10.1892 8.75401L9.47439 9.16661L10.9552 11.7312L9.86199 12.3624L8.79639 10.5166L8.08159 10.9294L9.14719 12.775L8.05539 13.4052L6.98999 11.5596L6.27519 11.9724L7.34059 13.818L6.24759 14.449L5.18199 12.6034L4.46719 13.0162L5.53279 14.8618L4.79559 15.2874L1.95279 10.3634Z" />
    </svg>
  )
}

export default RulerIcon
