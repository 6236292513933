import styles from '../../Footer/FooterSocialMedia.module.scss'

const Instagram = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M23.9004 23.9002C29.3678 18.4327 29.3678 9.56824 23.9004 4.10077C18.4329 -1.36669 9.56841 -1.36669 4.10095 4.10077C-1.36651 9.56823 -1.36651 18.4327 4.10095 23.9002C9.56841 29.3677 18.4329 29.3677 23.9004 23.9002Z"
          className={styles.background}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.88211 5.08203H18.1179C20.7636 5.08203 22.9397 7.2582 22.9397 9.90384V18.0967C22.9397 20.7423 20.7636 22.9185 18.1179 22.9185H9.88211C7.23647 22.9185 5.0603 20.7423 5.0603 18.0967V9.90384C5.0603 7.2582 7.23647 5.08203 9.88211 5.08203ZM18.8003 8.19715C19.3551 8.19715 19.8246 8.66663 19.8246 9.22142C19.8246 9.7762 19.3551 10.2457 18.8003 10.2457C18.2032 10.2457 17.7761 9.7762 17.7761 9.22142C17.7761 8.66663 18.2026 8.19715 18.8003 8.19715ZM13.9785 9.13611H14.0215C16.6671 9.13611 18.8863 11.3552 18.8863 14.0009C18.8863 16.6889 16.6671 18.8657 14.0215 18.8657H13.9785C11.3329 18.8657 9.15672 16.6895 9.15672 14.0009C9.15672 11.3552 11.3329 9.13611 13.9785 9.13611ZM13.9785 10.8005H14.0215C15.7711 10.8005 17.2219 12.2512 17.2219 14.0009C17.2219 15.7929 15.7711 17.2437 14.0215 17.2437H13.9785C12.2289 17.2437 10.7781 15.7929 10.7781 14.0009C10.7781 12.2512 12.2289 10.8005 13.9785 10.8005ZM9.92508 6.61873H18.075C19.9099 6.61873 21.403 8.11247 21.403 9.94681V18.0543C21.403 19.8893 19.9093 21.3824 18.075 21.3824H9.92508C8.09012 21.3824 6.59701 19.8887 6.59701 18.0543V9.94681C6.59701 8.11185 8.09075 6.61873 9.92508 6.61873Z"
          className={styles.icon}
        />
      </g>
    </svg>
  )
}

export default Instagram
