import { colors } from '../../../../constants/colors'

const MintsFull = () => {
  return (
    <svg
      width="71"
      height="22"
      viewBox="0 0 71 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3168 10.0373C15.5313 9.49256 14.8515 8.80427 14.3194 8.01041C13.8887 7.36435 13.5509 6.65917 13.3145 5.91598C13.0822 5.18968 12.9513 4.42961 12.926 3.66953V3.57663L12.0139 3.56396V12.858C10.7809 11.6039 8.76666 11.5828 7.51254 12.8158C6.25841 14.0488 6.2373 16.063 7.47031 17.3171C8.70332 18.5713 10.7175 18.5924 11.9717 17.3594C12.5628 16.7766 12.9049 15.987 12.926 15.1551V7.39813C13.1075 7.78239 13.3187 8.14976 13.5509 8.50446C14.1505 9.39967 14.9106 10.1724 15.7931 10.7847L16.169 11.0465L16.6884 10.3033L16.3125 10.0457L16.3168 10.0373ZM9.73787 17.3551C8.47952 17.3551 7.46186 16.3333 7.46609 15.0791C7.47031 13.825 8.48797 12.8031 9.7421 12.8073C10.9962 12.8073 12.0139 13.825 12.0139 15.0834C12.0139 16.3375 10.9962 17.3551 9.73787 17.3594"
        fill={`var(${colors.badgeBlack.token})`}
      />
      <path
        d="M59.7043 0H11.2959C5.22373 0 0.295898 4.92361 0.295898 11C0.295898 17.0722 5.2195 22 11.2959 22H59.7043C65.7765 22 70.7043 17.0764 70.7043 11C70.7043 4.92361 65.7807 0 59.7043 0ZM11.4521 20.2223C6.34695 20.2223 2.20876 16.0841 2.20876 10.9789C2.20876 5.87371 6.34695 1.73551 11.4521 1.73551C16.5573 1.73551 20.6955 5.87371 20.6955 10.9789C20.6955 16.0841 16.5573 20.2223 11.4521 20.2223Z"
        fill={`var(${colors.badgeBlack.token})`}
      />
      <path
        d="M26.9365 15.2139L28.5918 6.73486L31.3365 12.8577L34.1024 6.73486L35.593 15.2139H34.3642L33.5619 10.4592L31.269 15.5771L28.9972 10.4466L28.1695 15.2097H26.9407L26.9365 15.2139Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M38.4518 7.32617V15.2141H37.2441V7.32617H38.4518Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M40.3433 15.2144V6.79443L46.0649 12.7906V7.32649H47.2726V15.6958L41.5509 9.69962V15.2102H40.3433V15.2144Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M51.3519 8.46206V15.2141H50.1442V8.46206H48.3369V7.32617H53.1592V8.46206H51.3519Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M57.6308 9.07468C57.559 8.89733 57.4534 8.73265 57.2761 8.57641C57.0269 8.37372 56.7694 8.29349 56.4231 8.29349C55.6884 8.29349 55.3126 8.73265 55.3126 9.23936C55.3126 9.47583 55.397 9.88965 56.1655 10.1979L56.9594 10.5188C58.412 11.11 58.8174 11.9376 58.8174 12.8371C58.8174 14.3403 57.7532 15.3664 56.2753 15.3664C55.3632 15.3664 54.8227 15.0244 54.4174 14.5726C53.9909 14.0996 53.8008 13.5802 53.7544 13.0355L54.9494 12.7737C54.9494 13.1622 55.093 13.5296 55.2788 13.7787C55.5026 14.0616 55.8362 14.2516 56.3091 14.2516C57.0438 14.2516 57.6097 13.7196 57.6097 12.9257C57.6097 12.1319 56.9932 11.7898 56.4738 11.5787L55.7179 11.2578C55.0676 10.9875 54.1091 10.4428 54.1091 9.26048C54.1091 8.19637 54.9367 7.17871 56.4147 7.17871C57.2676 7.17871 57.749 7.49963 57.9982 7.71076C58.2093 7.90078 58.4373 8.17103 58.602 8.50462L57.6308 9.07046V9.07468Z"
        fill={`var(${colors.white.token})`}
      />
    </svg>
  )
}

export default MintsFull
