import { colors } from '../../../../constants/colors'

const DigitalDownloadFull = () => {
  return (
    <svg
      width="119"
      height="22"
      viewBox="0 0 119 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M107.419 0H11.5771C5.50498 0 0.577148 4.92361 0.577148 11C0.577148 17.0722 5.50076 22 11.5771 22H107.423C113.495 22 118.423 17.0764 118.423 11C118.423 4.92783 113.499 0 107.423 0H107.419ZM12.1557 20.2434C7.05047 20.2434 2.91227 16.1052 2.91227 11C2.91227 5.89482 7.05047 1.75662 12.1557 1.75662C17.2608 1.75662 21.399 5.89482 21.399 11C21.399 16.1052 17.2608 20.2434 12.1557 20.2434Z"
        fill={`var(${colors.badgeTurquoiseLight.token})`}
      />
      <path
        d="M14.7059 10.8017V5.29541H8.81109V10.8017H5.59766L11.7585 17.6213L17.9236 10.8017H14.7059Z"
        fill={`var(${colors.badgeTurquoiseLight.token})`}
      />
      <path
        d="M27.7793 6.84065C28.8772 6.84065 29.8357 6.95888 30.7098 7.60917C31.6684 8.33124 32.2215 9.47558 32.2215 10.7888C32.2215 12.1021 31.6768 13.238 30.638 13.9685C29.7175 14.6188 28.8519 14.7243 27.754 14.7243H26.124V6.83643H27.7793V6.84065ZM27.3317 13.5927H27.8511C28.4423 13.5927 29.2572 13.5462 29.9455 13.0353C30.4902 12.6215 31.0223 11.8656 31.0223 10.7888C31.0223 9.71205 30.524 8.93086 29.9582 8.51704C29.2741 8.01877 28.4338 7.97232 27.8553 7.97232H27.3359V13.5884L27.3317 13.5927Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M34.105 6.95068C34.5315 6.95068 34.8482 7.2716 34.8482 7.69387C34.8482 8.11613 34.5273 8.43706 34.105 8.43706C33.6827 8.43706 33.3618 8.11613 33.3618 7.69387C33.3618 7.2716 33.6827 6.95068 34.105 6.95068ZM34.6835 9.75452V14.733H33.5265V9.75452H34.6835Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M39.7888 9.75004H40.9458V14.3274C40.9458 15.4971 40.8149 16.3374 40.1308 16.9159C39.8099 17.1777 39.2905 17.4479 38.4755 17.4479C37.897 17.4479 37.3185 17.3044 36.8456 16.8314C36.4782 16.4641 36.1953 15.8856 36.1109 15.2817H37.2341C37.2805 15.552 37.4241 15.8729 37.6015 16.0629C37.745 16.2149 38.0279 16.4049 38.5009 16.4049C38.9738 16.4049 39.2694 16.2149 39.413 16.0756C39.793 15.7082 39.793 15.1888 39.793 14.8214V14.1712C39.261 14.8468 38.6107 14.8806 38.2813 14.8806C37.6437 14.8806 37.1116 14.7032 36.6387 14.2049C36.1911 13.732 35.8955 13.0564 35.8955 12.2287C35.8955 11.3166 36.2375 10.6917 36.6302 10.3032C37.1285 9.79649 37.7197 9.61914 38.3193 9.61914C38.8176 9.61914 39.3707 9.78382 39.7972 10.2948V9.75004H39.7888ZM37.5761 10.9915C37.2805 11.2618 37.0694 11.7262 37.0694 12.2583C37.0694 12.7566 37.2594 13.2379 37.5677 13.5124C37.7788 13.7024 38.0997 13.8418 38.4544 13.8418C38.8091 13.8418 39.1385 13.7362 39.4256 13.4406C39.7677 13.0733 39.8648 12.6468 39.8648 12.271C39.8648 11.7136 39.6874 11.2998 39.3919 11.0168C39.1089 10.7339 38.788 10.6621 38.4587 10.6621C38.1293 10.6621 37.8421 10.7466 37.5846 10.9915H37.5761Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M42.9937 6.95068C43.4202 6.95068 43.7369 7.2716 43.7369 7.69387C43.7369 8.11613 43.4159 8.43706 42.9937 8.43706C42.5714 8.43706 42.2505 8.11613 42.2505 7.69387C42.2505 7.2716 42.5714 6.95068 42.9937 6.95068ZM43.5722 9.75452V14.733H42.4152V9.75452H43.5722Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M46.2325 10.8142V14.7286H45.0754V10.8142H44.5898V9.75014H45.0754V7.93018H46.2325V9.75014H47.1192V10.8142H46.2325Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M51.5404 9.75021H52.6974V14.7287H51.5404V14.2093C51.0337 14.8005 50.4045 14.885 50.0498 14.885C48.5128 14.885 47.6387 13.6097 47.6387 12.2374C47.6387 10.6159 48.7492 9.62354 50.0625 9.62354C50.4298 9.62354 51.0675 9.71643 51.5404 10.3329V9.75444V9.75021ZM48.821 12.2585C48.821 13.1199 49.3657 13.842 50.206 13.842C50.9408 13.842 51.6122 13.3099 51.6122 12.2711C51.6122 11.2324 50.9366 10.6623 50.206 10.6623C49.3657 10.6623 48.821 11.3717 48.821 12.2585Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M55.324 6.11035V14.7288H54.167V6.11035H55.324Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M61.4131 6.84065C62.511 6.84065 63.4695 6.95888 64.3436 7.60917C65.3022 8.33124 65.8553 9.47558 65.8553 10.7888C65.8553 12.1021 65.3106 13.238 64.2718 13.9685C63.3513 14.6188 62.4856 14.7243 61.3878 14.7243H59.7578V6.83643H61.4131V6.84065ZM60.9655 13.5927H61.4849C62.076 13.5927 62.891 13.5462 63.5793 13.0353C64.124 12.6215 64.6561 11.8656 64.6561 10.7888C64.6561 9.71205 64.1578 8.93086 63.592 8.51704C62.9079 8.01877 62.0676 7.97232 61.4891 7.97232H60.9697V13.5884L60.9655 13.5927Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M72.2185 12.2456C72.2185 13.77 71.0615 14.8806 69.5582 14.8806C68.0549 14.8806 66.8979 13.77 66.8979 12.2456C66.8979 10.7213 68.0549 9.61914 69.5582 9.61914C71.0615 9.61914 72.2185 10.7086 72.2185 12.2456ZM71.0361 12.2583C71.0361 11.1224 70.293 10.6621 69.5582 10.6621C68.8235 10.6621 68.0803 11.1224 68.0803 12.2583C68.0803 13.2168 68.6461 13.8418 69.5582 13.8418C70.4703 13.8418 71.0361 13.2168 71.0361 12.2583Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M74.0638 9.75022L75.3517 12.6005L76.7451 9.32373L78.1386 12.6005L79.4265 9.75022H80.7271L78.1386 15.0834L76.7451 11.9038L75.3517 15.0834L72.7632 9.75022H74.0638Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M81.5254 9.75004H82.6824V10.2103C83.1807 9.65292 83.7465 9.61914 84.0294 9.61914C84.95 9.61914 85.3427 10.0794 85.4947 10.2948C85.6847 10.565 85.8029 10.9113 85.8029 11.6798V14.7285H84.6459V11.9627C84.6459 10.6959 84.173 10.6621 83.7338 10.6621C83.2144 10.6621 82.6824 10.7339 82.6824 12.3427V14.7328H81.5254V9.75427V9.75004Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M88.4295 6.11035V14.7288H87.2725V6.11035H88.4295Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M94.9577 12.2456C94.9577 13.77 93.8007 14.8806 92.2975 14.8806C90.7942 14.8806 89.6372 13.77 89.6372 12.2456C89.6372 10.7213 90.7942 9.61914 92.2975 9.61914C93.8007 9.61914 94.9577 10.7086 94.9577 12.2456ZM93.7754 12.2583C93.7754 11.1224 93.0322 10.6621 92.2975 10.6621C91.5627 10.6621 90.8195 11.1224 90.8195 12.2583C90.8195 13.2168 91.3854 13.8418 92.2975 13.8418C93.2096 13.8418 93.7754 13.2168 93.7754 12.2583Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M99.805 9.75021H100.962V14.7287H99.805V14.2093C99.2983 14.8005 98.6692 14.885 98.3145 14.885C96.7774 14.885 95.9033 13.6097 95.9033 12.2374C95.9033 10.6159 97.0139 9.62354 98.3271 9.62354C98.6945 9.62354 99.3321 9.71643 99.805 10.3329V9.75444V9.75021ZM97.0857 12.2585C97.0857 13.1199 97.6304 13.842 98.4707 13.842C99.2054 13.842 99.8768 13.3099 99.8768 12.2711C99.8768 11.2324 99.2012 10.6623 98.4707 10.6623C97.6304 10.6623 97.0857 11.3717 97.0857 12.2585Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M106.072 6.11035H107.229V14.7288H106.072V14.2094C105.565 14.8006 104.936 14.885 104.581 14.885C103.044 14.885 102.17 13.6098 102.17 12.2374C102.17 10.6159 103.28 9.62359 104.594 9.62359C104.961 9.62359 105.599 9.71649 106.072 10.333V6.11035ZM103.352 12.2585C103.352 13.1199 103.897 13.842 104.737 13.842C105.472 13.842 106.143 13.31 106.143 12.2712C106.143 11.2324 105.468 10.6624 104.737 10.6624C103.897 10.6624 103.352 11.3718 103.352 12.2585Z"
        fill={`var(${colors.white.token})`}
      />
    </svg>
  )
}

export default DigitalDownloadFull
