const SaveIcon = () => {
  return (
    <svg
      width="23"
      height="21"
      viewBox="0 0 23 21"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.8389 9.63996V4.96693C17.8389 3.76382 16.8535 2.78532 15.6419 2.78532H10.8526L10.317 1.8817C9.91137 1.20011 9.22236 0.807678 8.42414 0.807678H2.19704C0.985417 0.807678 0 1.78617 0 2.98928V13.6262C0 14.8293 0.985417 15.8078 2.19704 15.8078H11.6274C11.9004 18.6788 14.3392 20.9327 17.3007 20.9327C20.4442 20.9327 23 18.3948 23 15.2734C23 12.3328 20.7302 9.91105 17.8389 9.63996ZM2.19704 1.87912H8.42414C8.83495 1.87912 9.17816 2.07275 9.38876 2.42646L10.0804 3.59342C10.1766 3.75607 10.3534 3.85676 10.5458 3.85676H15.6445C16.2607 3.85676 16.7625 4.35505 16.7625 4.96693V6.15455H1.07902V2.99187C1.07902 2.3774 1.58083 1.87912 2.19704 1.87912ZM1.07902 13.6262V7.22341H16.7625V9.63996C14.048 9.89297 11.8848 12.0436 11.6274 14.739H2.19704C1.58083 14.739 1.07902 14.2407 1.07902 13.6262ZM17.3007 19.8638C14.7527 19.8638 12.6804 17.8061 12.6804 15.2734C12.6804 12.7433 14.7527 10.6856 17.3007 10.6856C19.8487 10.6856 21.9236 12.7433 21.9236 15.2734C21.921 17.8036 19.8487 19.8638 17.3007 19.8638ZM20.3012 15.2734C20.3012 15.5703 20.0593 15.8078 19.7629 15.8078H17.8389V17.7184C17.8389 18.0153 17.5971 18.2528 17.3007 18.2528C17.0043 18.2528 16.7625 18.0127 16.7625 17.7184V15.8078H14.8385C14.5395 15.8078 14.3002 15.5677 14.3002 15.2734C14.3002 14.9791 14.5421 14.739 14.8385 14.739H16.7625V12.8285C16.7625 12.5316 17.0043 12.294 17.3007 12.294C17.5971 12.294 17.8389 12.5341 17.8389 12.8285V14.739H19.7629C20.0593 14.739 20.3012 14.9765 20.3012 15.2734Z" />
    </svg>
  )
}

export default SaveIcon
