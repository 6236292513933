import type { IconProps } from '../../../typings/props'

const ShippingIcon = ({ className, title }: IconProps) => {
  return (
    <svg
      className={className}
      fill="currentColor"
      width="28"
      height="20"
      viewBox="0 0 28 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      {title ? <title>{title}</title> : null}

      <path d="M21.4311 19.7499C20.7498 19.7499 20.0838 19.5479 19.5173 19.1693C18.9508 18.7908 18.5093 18.2528 18.2485 17.6234C17.9878 16.9939 17.9196 16.3013 18.0525 15.6331C18.1854 14.9649 18.5135 14.3511 18.9953 13.8693C19.477 13.3875 20.0908 13.0595 20.7591 12.9265C21.4273 12.7936 22.1199 12.8618 22.7494 13.1226C23.3788 13.3833 23.9168 13.8248 24.2953 14.3913C24.6738 14.9578 24.8759 15.6238 24.8759 16.3051C24.8759 17.2187 24.5129 18.0949 23.8669 18.7409C23.2209 19.387 22.3447 19.7499 21.4311 19.7499ZM21.4311 14.2383C21.0223 14.2383 20.6227 14.3595 20.2828 14.5866C19.9429 14.8137 19.678 15.1365 19.5216 15.5142C19.3651 15.8918 19.3242 16.3074 19.404 16.7083C19.4837 17.1093 19.6806 17.4776 19.9696 17.7666C20.2587 18.0557 20.6269 18.2525 21.0279 18.3323C21.4288 18.412 21.8444 18.3711 22.2221 18.2147C22.5997 18.0582 22.9225 17.7933 23.1496 17.4534C23.3767 17.1135 23.498 16.7139 23.498 16.3051C23.498 15.757 23.2802 15.2312 22.8926 14.8436C22.505 14.456 21.9793 14.2383 21.4311 14.2383Z" />
      <path d="M27.1723 16.0756H23.4978L23.3325 15.6163C23.1942 15.2121 22.9331 14.8612 22.5855 14.6128C22.238 14.3643 21.8214 14.2308 21.3942 14.2308C20.967 14.2308 20.5505 14.3643 20.203 14.6128C19.8554 14.8612 19.5942 15.2121 19.456 15.6163L19.2906 16.0756H15.6162V2.75586H22.1016L27.0988 7.75307L27.1723 16.0756ZM24.4624 14.6977H25.7944V8.3226L21.6055 4.13377H17.0676V14.6977H18.3996C18.6896 14.1434 19.1259 13.679 19.6611 13.355C20.1963 13.031 20.8099 12.8597 21.4356 12.8597C22.0612 12.8597 22.6749 13.031 23.2101 13.355C23.7453 13.679 24.1815 14.1434 24.4716 14.6977H24.4624Z" />
      <path d="M8.57075 19.7499C7.88943 19.7499 7.22342 19.5479 6.65694 19.1693C6.09045 18.7908 5.64892 18.2528 5.3882 17.6234C5.12747 16.9939 5.05925 16.3013 5.19217 15.6331C5.32509 14.9649 5.65317 14.3511 6.13493 13.8693C6.61669 13.3875 7.23049 13.0595 7.89871 12.9265C8.56692 12.7936 9.25955 12.8618 9.889 13.1226C10.5184 13.3833 11.0564 13.8248 11.435 14.3913C11.8135 14.9578 12.0155 15.6238 12.0155 16.3051C12.0155 17.2187 11.6526 18.0949 11.0066 18.7409C10.3605 19.387 9.48435 19.7499 8.57075 19.7499ZM8.57075 14.2383C8.16196 14.2383 7.76235 14.3595 7.42246 14.5866C7.08257 14.8137 6.81765 15.1365 6.66122 15.5142C6.50478 15.8918 6.46385 16.3074 6.5436 16.7083C6.62335 17.1093 6.8202 17.4776 7.10925 17.7666C7.39831 18.0557 7.76659 18.2525 8.16752 18.3323C8.56845 18.412 8.98403 18.3711 9.3617 18.2147C9.73937 18.0582 10.0622 17.7933 10.2893 17.4534C10.5164 17.1135 10.6376 16.7139 10.6376 16.3051C10.6376 15.757 10.4198 15.2312 10.0322 14.8436C9.64462 14.456 9.11891 14.2383 8.57075 14.2383Z" />
      <path d="M17.0677 16.0756H10.6374L10.4721 15.6163C10.3338 15.2121 10.0727 14.8612 9.72512 14.6127C9.37757 14.3643 8.96105 14.2307 8.53384 14.2307C8.10662 14.2307 7.6901 14.3643 7.34255 14.6127C6.99501 14.8612 6.73384 15.2121 6.59558 15.6163L6.43023 16.0756H0V0H16.9942L17.0677 16.0756ZM11.602 14.6977H15.6898V1.37791H1.4514V14.6977H5.53919C5.82921 14.1433 6.26548 13.679 6.80067 13.355C7.33586 13.031 7.94955 12.8597 8.57517 12.8597C9.2008 12.8597 9.81449 13.031 10.3497 13.355C10.8849 13.679 11.3211 14.1433 11.6112 14.6977H11.602Z" />
      <path d="M27.1724 10.564H19.3643V2.75586H22.1752L27.1724 7.75307V10.564ZM20.7422 9.18609H25.7945V8.3226L21.6057 4.13377H20.7422V9.18609Z" />
    </svg>
  )
}

export default ShippingIcon
