import { colors } from '../../../../constants/colors'

const EditorsChoiceFull = () => {
  return (
    <svg
      width="618"
      height="207.6"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 618 207.6"
      fill="#fff"
    >
      <g>
        <path
          fill={`var(${colors.white.token})`}
          strokeWidth="0"
          d="M160.7,79.4c0-41.3-33.8-75.1-75.2-75.1S10.6,38.1,10.6,79.4s33.6,75.1,75.1,75.1,75.1-33.6,75.1-75.1h0Z"
        />
        <path
          fill={`var(${colors.badgeRedEditor.token})`}
          strokeWidth="0"
          d="M84.5,15.9c-35,0-63.5,28.5-63.5,63.5s28.5,63.5,63.5,63.5h0c35,0,63.5-28.5,63.5-63.5S119.6,15.9,84.5,15.9ZM31.9,79.4c0-29,23.6-52.7,52.7-52.7s52.7,23.6,52.7,52.7-23.6,52.7-52.7,52.7-52.7-23.6-52.7-52.7Z"
        />
      </g>
      <path
        fill={`var(${colors.badgeRedEditor.token})`}
        strokeWidth="0"
        d="M561.3,22.6H134.5c16.1,13,26.1,34.7,26.1,56.8s-10.7,44.2-26,56.8h426.6c31.4,0,56.8-25.4,56.8-56.8s-25.5-56.8-56.8-56.8h0Z"
      />
      <g>
        <path
          fill={`var(${colors.white.token})`}
          strokeWidth="0"
          d="M220.5,63.5h-16.2v10.3h15.7v5.8h-15.7v12.8h16.2v5.8h-22.4v-40.6h22.4v5.8h0Z"
        />
        <path
          fill={`var(${colors.white.token})`}
          strokeWidth="0"
          d="M246.2,53.9h6v44.3h-6v-2.7c-2.6,3-5.8,3.5-7.7,3.5-7.9,0-12.4-6.6-12.4-13.6s5.7-13.4,12.5-13.4,5.2.5,7.6,3.7v-21.7h0ZM232.2,85.5c0,4.4,2.8,8.2,7.1,8.2s7.2-2.7,7.2-8.1-3.5-8.3-7.2-8.3-7.1,3.6-7.1,8.2Z"
        />
        <path
          fill={`var(${colors.white.token})`}
          strokeWidth="0"
          d="M262.6,58.2c2.2,0,3.8,1.6,3.8,3.8s-1.6,3.8-3.8,3.8-3.8-1.6-3.8-3.8,1.6-3.8,3.8-3.8ZM265.6,72.6v25.6h-6v-25.6h6Z"
        />
        <path
          fill={`var(${colors.white.token})`}
          strokeWidth="0"
          d="M279.3,78.1v20.1h-6v-20.1h-2.5v-5.5h2.5v-9.4h6v9.4h4.6v5.5h-4.6Z"
        />
        <path
          fill={`var(${colors.white.token})`}
          strokeWidth="0"
          d="M313.9,85.5c0,7.8-6,13.6-13.7,13.6s-13.7-5.7-13.7-13.6,6-13.5,13.7-13.5,13.7,5.6,13.7,13.5ZM307.8,85.5c0-5.8-3.8-8.2-7.6-8.2s-7.6,2.4-7.6,8.2,2.9,8.2,7.6,8.2,7.6-3.2,7.6-8.2Z"
        />
        <path
          fill={`var(${colors.white.token})`}
          strokeWidth="0"
          d="M320.1,72.6h6v2.3c.7-.8,1.6-1.6,2.5-2.1,1.2-.7,2.3-.8,3.6-.8s3,.2,4.7,1.2l-2.4,5.4c-1.3-.8-2.4-.9-3-.9-1.3,0-2.6.2-3.7,1.4-1.6,1.8-1.6,4.2-1.6,5.9v13.3h-6v-25.6h-.1Z"
        />
        <path
          fill={`var(${colors.white.token})`}
          strokeWidth="0"
          d="M350.3,79.4c-.6-1-1.8-2.2-3.6-2.2s-1.5.3-1.8.7c-.3.3-.5.8-.5,1.3s.3,1.1.9,1.5c.4.2.7.4,2,1l3,1.5c1.3.7,2.6,1.3,3.6,2.4,1.4,1.4,1.9,3,1.9,4.7s-.9,4.3-2.2,5.8c-2,2.2-4.5,2.9-7.2,2.9s-3.7-.2-5.5-1.6c-1.5-1.1-2.7-2.9-3.3-4.7l4.9-2.3c.3.8.9,1.9,1.4,2.4.4.4,1.2,1,2.6,1s1.9-.3,2.4-.8.9-1.3.9-2-.2-1.3-.8-1.9c-.7-.7-1.6-1.2-2.5-1.6l-2.2-1c-1.2-.6-2.5-1.2-3.5-2.2-1.3-1.3-1.8-2.7-1.8-4.3s.8-4.1,2.1-5.5c1.2-1.2,3.1-2.4,6.3-2.4s3.3.4,4.7,1.3c.9.5,2,1.5,2.9,3.2l-4.4,2.9h-.3Z"
        />
        <path
          fill={`var(${colors.white.token})`}
          strokeWidth="0"
          d="M367.2,55.6l5.4,2.1-7.5,15-4-1.6,6.2-15.4h-.1Z"
        />
        <path
          fill={`var(${colors.white.token})`}
          strokeWidth="0"
          d="M424.6,67.2c-4.5-4.1-8.7-4.5-11-4.5-8.8,0-14.8,6.5-14.8,15.4s6.2,15.2,14.8,15.2,8.6-2.5,10.9-4.7v7.4c-4.1,2.4-8.3,3-11.1,3-7.4,0-12-3.3-14.4-5.6-4.7-4.4-6.5-9.6-6.5-15.3s3.1-12.7,6.5-15.9c4.2-4,9.1-5.3,14.6-5.3s7.3.7,10.8,2.9v7.3h.2Z"
        />
        <path
          fill={`var(${colors.white.token})`}
          strokeWidth="0"
          d="M431.2,53.9h6v20.9c2.6-2.6,5.4-2.9,6.9-2.9,4.7,0,6.8,2.4,7.5,3.5,1.6,2.3,1.6,5.3,1.6,7.1v15.7h-6v-15.1c0-1.5,0-3.2-1.2-4.4-.7-.7-1.8-1.3-3.3-1.3s-3.6,1-4.4,2.1c-.9,1.2-1.2,3.2-1.2,6.4v12.3h-6v-44.3s.1,0,.1,0Z"
        />
        <path
          fill={`var(${colors.white.token})`}
          strokeWidth="0"
          d="M486.1,85.5c0,7.8-6,13.6-13.7,13.6s-13.7-5.7-13.7-13.6,6-13.5,13.7-13.5,13.7,5.6,13.7,13.5ZM480,85.5c0-5.8-3.8-8.2-7.6-8.2s-7.6,2.4-7.6,8.2,2.9,8.2,7.6,8.2,7.6-3.2,7.6-8.2Z"
        />
        <path
          fill={`var(${colors.white.token})`}
          strokeWidth="0"
          d="M495.3,58.2c2.2,0,3.8,1.6,3.8,3.8s-1.6,3.8-3.8,3.8-3.8-1.6-3.8-3.8,1.6-3.8,3.8-3.8ZM498.2,72.6v25.6h-6v-25.6h6Z"
        />
        <path
          fill={`var(${colors.white.token})`}
          strokeWidth="0"
          d="M525.6,80.5c-2.7-2.7-5.4-3.2-7.2-3.2-2.8,0-4.6,1.2-5.7,2.2-1.1,1.1-2.2,3-2.2,5.9s1.2,5,2.3,6.1c1.3,1.3,3.2,2.1,5.4,2.1s5.3-1,7.4-3.3v6.6c-1.5.9-3.8,2-7.8,2s-7.3-1.2-9.7-3.8c-1.8-1.9-3.6-4.9-3.6-9.6s2-8,3.9-9.9c2-2,5.2-3.8,10.3-3.8s4.3.3,7,1.8v6.7h0v.2h0Z"
        />
        <path
          fill={`var(${colors.white.token})`}
          strokeWidth="0"
          d="M554.2,92.4c-1.1,1.9-2.7,3.7-4.3,4.7-2.1,1.3-4.8,1.9-7.5,1.9s-6.2-.8-8.6-3.3-3.8-6-3.8-9.8,1.4-7.8,4.1-10.5c2.1-2.1,4.9-3.5,8.6-3.5s6.7,1.8,8.2,3.3c3.3,3.4,3.6,8.2,3.6,10.8v.7h-18.4c0,1.8.9,3.9,2.2,5.2,1.4,1.4,3.1,1.6,4.6,1.6s2.9-.4,4.1-1.3c1-.8,1.8-1.8,2.4-2.8l5,2.8h0l-.2.2h0ZM548.3,82c-.2-1.4-.9-2.6-1.8-3.5-.8-.7-2.1-1.5-4-1.5s-3.4.8-4.2,1.6-1.5,2-1.8,3.3h11.9-.1Z"
        />
      </g>
      <path
        fill={`var(${colors.badgeRedEditor.token})`}
        strokeWidth="0"
        d="M127.4,195.1l-42.8-24.9-43.3,24.9v-55.2s17.9,14.5,43.3,14.5,42.8-12.7,42.8-12.7v53.4h0Z"
      />
      <polygon
        fill={`var(${colors.badgeRedEditor.token})`}
        strokeWidth="0"
        points="79.2 114.6 49 90.5 57.4 79.9 76.9 95.4 109.9 53.1 120.7 61.5 79.2 114.6"
      />
    </svg>
  )
}

export default EditorsChoiceFull
