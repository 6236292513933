const AudioIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="currentColor"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.8996 5.10044C22.2551 2.45631 18.7396 1 15 1C11.2604 1 7.74489 2.45631 5.10044 5.10044C2.45631 7.74489 1 11.2604 1 15C1 18.7396 2.45631 22.2551 5.10044 24.8996C7.74489 27.5437 11.2604 29 15 29C18.7396 29 22.2551 27.5437 24.8996 24.8996C27.5437 22.2551 29 18.7396 29 15C29 11.2604 27.5437 7.74489 24.8996 5.10044ZM15 27.9061C7.88333 27.9061 2.09387 22.1167 2.09387 15C2.09387 7.88333 7.88333 2.09387 15 2.09387C22.1167 2.09387 27.9061 7.88333 27.9061 15C27.9061 22.1167 22.1167 27.9061 15 27.9061Z"
        strokeWidth="0.3"
      />
      <path
        d="M22.4984 14.5272L11.3298 8.07913C11.1606 7.98144 10.9521 7.98144 10.7829 8.07913C10.6136 8.17681 10.5094 8.35726 10.5094 8.55264V21.4488C10.5094 21.6442 10.6136 21.8246 10.7829 21.9223C10.8675 21.9712 10.9618 21.9957 11.0563 21.9957C11.1509 21.9957 11.2452 21.9712 11.3298 21.9223L22.4984 15.4742C22.6676 15.3765 22.7719 15.1961 22.7719 15.0007C22.7719 14.8053 22.6676 14.6249 22.4984 14.5272ZM11.6033 20.5018V9.49966L21.1311 15.0007L11.6033 20.5018Z"
        strokeWidth="0.3"
      />
    </svg>
  )
}

export default AudioIcon
