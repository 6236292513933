const ScoreIcon = () => {
  return (
    <svg
      width="23"
      height="28"
      viewBox="0 0 23 28"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.8427 17.3379H6.06017C5.68857 17.3379 5.3851 17.6382 5.3851 18.0059C5.3851 18.3736 5.68857 18.6738 6.06017 18.6738H16.8427C17.2143 18.6738 17.5178 18.3736 17.5178 18.0059C17.5178 17.6382 17.2143 17.3379 16.8427 17.3379Z" />
      <path d="M16.8427 21.0215H6.06017C5.68857 21.0215 5.3851 21.3218 5.3851 21.6895C5.3851 22.0572 5.68857 22.3574 6.06017 22.3574H16.8427C17.2143 22.3574 17.5178 22.0572 17.5178 21.6895C17.5178 21.3218 17.2143 21.0215 16.8427 21.0215Z" />
      <path d="M10.7812 14.6645C12.2676 14.6645 13.4753 13.4695 13.4753 11.9987V5.7418L15.197 6.59363C15.5314 6.75909 15.934 6.62427 16.1012 6.29334C16.2685 5.96242 16.1322 5.56408 15.7978 5.39862L13.1037 4.06266C12.8931 3.95848 12.6454 3.97073 12.4472 4.0933C12.249 4.21586 12.1251 4.43035 12.1251 4.66323V9.6884C11.7288 9.46165 11.2704 9.33296 10.775 9.33296C9.28858 9.33296 8.08089 10.528 8.08089 11.9987C8.08089 13.4695 9.28858 14.6645 10.775 14.6645H10.7812ZM10.7812 10.6628C11.5244 10.6628 12.1313 11.2572 12.1313 11.9987C12.1313 12.7403 11.5306 13.3347 10.7812 13.3347C10.0318 13.3347 9.43103 12.7403 9.43103 11.9987C9.43103 11.2572 10.0318 10.6628 10.7812 10.6628Z" />
      <path d="M19.5399 0H3.36917C1.50498 0 0 1.49529 0 3.33377V24.6662C0 26.5047 1.50498 28 3.36917 28H19.5399C21.3979 28 22.9091 26.5047 22.9091 24.6662V3.33377C22.9091 1.49529 21.4041 0 19.5399 0ZM21.5589 24.6662C21.5589 25.7693 20.6547 26.664 19.5399 26.664H3.36917C2.25437 26.664 1.35014 25.7693 1.35014 24.6662V3.33377C1.35014 2.23069 2.25437 1.33596 3.36917 1.33596H19.5399C20.6547 1.33596 21.5589 2.23069 21.5589 3.33377V24.6662Z" />
    </svg>
  )
}

export default ScoreIcon
