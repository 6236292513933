import type { IconProps } from '../../../typings/props'

const CircledArrow = ({ className, title }: IconProps) => {
  return (
    <svg
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      {title ? <title>{title}</title> : null}

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9997 21.834C16.9829 21.834 21.833 16.9839 21.833 11.0007C21.833 5.01741 16.9829 0.167316 10.9997 0.167315C5.01644 0.167315 0.166341 5.01741 0.166341 11.0006C0.16634 16.9839 5.01644 21.834 10.9997 21.834ZM20.1663 11.0007C20.1663 16.0634 16.0624 20.1673 10.9997 20.1673C5.93691 20.1673 1.83301 16.0634 1.83301 11.0006C1.83301 5.93789 5.93691 1.83398 10.9997 1.83398C16.0624 1.83398 20.1663 5.93789 20.1663 11.0007ZM8.91042 14.9114C8.58498 15.2368 8.58498 15.7645 8.91042 16.0899C9.23586 16.4153 9.76349 16.4153 10.0889 16.0899L14.5889 11.5899C14.9144 11.2645 14.9144 10.7368 14.5889 10.4114L10.0889 5.91139C9.76349 5.58596 9.23586 5.58596 8.91042 5.91139C8.58498 6.23683 8.58498 6.76447 8.91042 7.08991L12.8212 11.0007L8.91042 14.9114Z"
      />
    </svg>
  )
}

export default CircledArrow
