const FullArrowLeftIcon = () => {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M.237 4.405 3.5531.828c.349-.3766.9225-.3766 1.284 0 .3491.3765.3491.9951 0 1.3851L3.0795 4.1092h7.8289c.4987 0 .9101.4438.9101.9817 0 .538-.4114.9817-.9101.9817H3.0669l1.7703 1.8962c.349.3766.349.9952 0 1.3852-.1746.1882-.4114.2824-.6358.2824-.2369 0-.4613-.0942-.6358-.2824L.262 5.7902C.0874 5.602.0002 5.36.0002 5.1044c0-.2555.0748-.5245.2368-.6993Z" />
    </svg>
  )
}

export default FullArrowLeftIcon
