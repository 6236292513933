export const breakpoints = {
  phone: '320px',
  phonemid: '375px',
  phonelg: '500px',
  tablet: '768px',
  tabletlg: '900px',
  notebook: '1100px',
  notebookwide: '1300px',
  desktop: '1500px',
  desktopwide: '1650px',
  contentNarrow: '80rem',
  contentWide: '95.5rem',
  layoutContent: '99.375rem',
}
