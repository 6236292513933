import styles from '../../Footer/FooterSocialMedia.module.scss'

const Twitter = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M23.9004 23.9002C29.3678 18.4327 29.3678 9.56824 23.9004 4.10077C18.4329 -1.36669 9.56841 -1.36669 4.10095 4.10077C-1.36651 9.56823 -1.36651 18.4327 4.10095 23.9002C9.56841 29.3677 18.4329 29.3677 23.9004 23.9002Z"
          className={styles.background}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1548 11.5115C13.4649 7.87833 17.834 5.71711 20.4087 8.33847C20.4087 8.33847 21.5581 8.01656 22.5699 7.37274C22.5699 7.37274 22.2019 8.6143 20.9603 9.39635C20.9603 9.39635 22.3401 9.21267 22.9379 8.84469C22.9379 8.84469 22.3401 10.0863 21.2362 10.5464C21.8339 18.87 12.8665 23.9284 5.60077 19.7896C5.60077 19.7896 9.18786 19.9733 10.7053 18.2262C10.7053 18.2262 8.49797 18.4099 7.394 15.743C7.394 15.743 8.22151 16.0649 9.04963 15.6509C9.04963 15.6509 6.47435 15.2368 6.24459 12.1559C6.24459 12.1559 7.07209 12.7998 7.99237 12.57C7.99237 12.57 5.14125 10.8683 6.75081 7.78742C6.75081 7.78742 9.78623 11.6504 14.1548 11.5121V11.5115Z"
          className={styles.icon}
        />
      </g>
    </svg>
  )
}

export default Twitter
