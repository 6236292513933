import { colors } from '../../../../constants/colors'

const EPrintFull = () => {
  return (
    <svg
      width="63"
      height="22"
      viewBox="0 0 63 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.642 0H11.3579C5.28574 0 0.35791 4.92361 0.35791 11C0.35791 17.0722 5.28152 22 11.3579 22H51.642C57.7141 22 62.642 17.0764 62.642 11C62.642 4.92361 57.7184 0 51.642 0ZM12.0673 20.2223C6.96213 20.2223 2.82394 16.0841 2.82394 10.9789C2.82394 5.8737 6.96213 1.73551 12.0673 1.73551C17.1725 1.73551 21.3107 5.8737 21.3107 10.9789C21.3107 16.0841 17.1725 20.2223 12.0673 20.2223Z"
        fill={`var(${colors.badgeTurquoiseLight.token})`}
      />
      <path
        d="M17.0331 16.1895C15.6481 17.5745 13.8028 18.343 11.8434 18.343C7.78548 18.3473 4.47493 15.0536 4.4707 10.9914C4.4707 9.01944 5.23078 7.16569 6.6327 5.77222C8.01773 4.38719 9.86725 3.61867 11.8266 3.61867C13.7943 3.61445 15.6438 4.37875 17.0415 5.768C18.4392 7.15725 19.2035 9.00255 19.2077 10.9703C19.2077 11.2025 19.1951 11.4432 19.1655 11.7135L5.99508 11.7388L6.03308 11.9711C6.51446 14.8256 8.9636 16.8905 11.8519 16.8862C13.4185 16.8862 14.888 16.2697 15.9985 15.1634C16.4377 14.7242 16.7966 14.2344 17.0795 13.6897L18.5152 14.1162C18.1394 14.8889 17.6454 15.5899 17.0373 16.1937L17.0331 16.1895ZM7.67147 6.81099C6.78893 7.69353 6.2231 8.80408 6.01619 10.0202L5.97819 10.2525L17.6749 10.2356L17.6369 10.0033C17.1555 7.1488 14.7064 5.08393 11.8181 5.08815C10.2557 5.08815 8.78203 5.70044 7.67147 6.81099Z"
        fill={`var(${colors.badgeTurquoiseLight.token})`}
      />
      <path
        d="M31.4958 13.9136C31.2847 14.2937 30.9764 14.623 30.6555 14.8257C30.2544 15.0875 29.7223 15.2058 29.1903 15.2058C28.5273 15.2058 27.9826 15.0411 27.5096 14.5681C27.0367 14.0952 26.7749 13.4111 26.7749 12.6637C26.7749 11.9163 27.0452 11.152 27.5688 10.62C27.9826 10.2061 28.5273 9.94434 29.2367 9.94434C30.0306 9.94434 30.5373 10.2864 30.8329 10.5946C31.4705 11.2576 31.5296 12.1781 31.5296 12.6975V12.8411H27.9572C27.9826 13.1958 28.1346 13.5969 28.3837 13.8461C28.654 14.1163 28.9876 14.167 29.2705 14.167C29.5914 14.167 29.8279 14.0952 30.0643 13.9178C30.267 13.7658 30.4191 13.5631 30.5246 13.3731L31.4958 13.9178V13.9136ZM30.3473 11.891C30.3008 11.6207 30.1699 11.3843 29.9926 11.2154C29.8405 11.0718 29.5914 10.9324 29.2114 10.9324C28.8102 10.9324 28.5484 11.0971 28.3964 11.2534C28.2317 11.4054 28.1008 11.6418 28.0417 11.891H30.3473Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M34.688 7.16162C35.1736 7.16162 35.845 7.20807 36.415 7.58811C37.078 8.02726 37.3947 8.78312 37.3947 9.50519C37.3947 9.96546 37.2765 10.6749 36.6262 11.2323C36.0012 11.7643 35.2791 11.8488 34.7006 11.8488H34.1559V15.0538H32.9482V7.16584H34.688V7.16162ZM34.1559 10.734H34.7006C35.7394 10.734 36.2377 10.2273 36.2377 9.49252C36.2377 9.05337 36.0603 8.2764 34.688 8.2764H34.1559V10.734Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M38.4126 10.0708H39.5696V10.5184C39.7005 10.3664 39.8905 10.1975 40.0552 10.1046C40.279 9.97372 40.5028 9.93994 40.7646 9.93994C41.0475 9.93994 41.3558 9.98639 41.6767 10.1764L41.2038 11.2278C40.942 11.0632 40.7308 11.0505 40.6126 11.0505C40.3635 11.0505 40.1143 11.0843 39.8905 11.3207C39.5696 11.6628 39.5696 12.1357 39.5696 12.4693V15.0451H38.4126V10.0666V10.0708Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M42.918 7.26709C43.3445 7.26709 43.6612 7.58801 43.6612 8.01028C43.6612 8.43254 43.3403 8.75346 42.918 8.75346C42.4957 8.75346 42.1748 8.43254 42.1748 8.01028C42.1748 7.58801 42.4957 7.26709 42.918 7.26709ZM43.4965 10.0709V15.0494H42.3395V10.0709H43.4965Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M44.9492 10.0708H46.1062V10.5311C46.6045 9.97372 47.1703 9.93994 47.4532 9.93994C48.3738 9.93994 48.7665 10.4002 48.9185 10.6156C49.1085 10.8858 49.2268 11.2321 49.2268 12.0006V15.0493H48.0698V12.2835C48.0698 11.0167 47.5968 10.9829 47.1577 10.9829C46.6383 10.9829 46.1062 11.0547 46.1062 12.6635V15.0536H44.9492V10.0751V10.0708Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M51.8911 11.135V15.0494H50.7341V11.135H50.2485V10.0709H50.7341V8.25098H51.8911V10.0709H52.7779V11.135H51.8911Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M53.7578 6.47754C54.2518 6.47754 54.6107 6.84069 54.6107 7.33051C54.6107 7.82034 54.2518 8.18349 53.7578 8.18349C53.2637 8.18349 52.9048 7.82034 52.9048 7.33051C52.9048 6.84069 53.2637 6.47754 53.7578 6.47754ZM53.7578 6.6C53.3397 6.6 53.0441 6.92092 53.0441 7.33051C53.0441 7.74011 53.3439 8.06103 53.7578 8.06103C54.1716 8.06103 54.4714 7.74011 54.4714 7.33051C54.4714 6.92092 54.1716 6.6 53.7578 6.6ZM53.9604 7.77389L53.6733 7.41074V7.77389H53.5002V6.85336H53.7451C54.0365 6.85336 54.0745 7.03915 54.0745 7.12783C54.0745 7.26295 53.9942 7.35585 53.8718 7.38963L54.1758 7.77389H53.9604ZM53.6733 7.26295H53.7071C53.8338 7.26295 53.8971 7.21228 53.8971 7.13205C53.8971 7.01382 53.7747 7.00537 53.7113 7.00537H53.6733V7.26295Z"
        fill={`var(${colors.white.token})`}
      />
    </svg>
  )
}

export default EPrintFull
