import styles from '../../Footer/FooterSocialMedia.module.scss'

const LinkedIn = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M23.9004 23.9002C29.3678 18.4327 29.3678 9.56824 23.9004 4.10077C18.4329 -1.36669 9.56841 -1.36669 4.10095 4.10077C-1.36651 9.56823 -1.36651 18.4327 4.10095 23.9002C9.56841 29.3677 18.4329 29.3677 23.9004 23.9002Z"
          className={styles.background}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.21343 11.1895H9.36653V21.777H6.21343V11.1895ZM7.77068 5.81787C8.82171 5.81787 9.67786 6.67402 9.67786 7.72505C9.67786 8.77609 8.82171 9.63223 7.77068 9.63223C6.71964 9.63223 5.9021 8.77609 5.9021 7.72505C5.9021 6.67402 6.71964 5.81787 7.77068 5.81787ZM11.2351 11.1895H14.349V12.6689C15.0109 11.6957 16.1005 11.073 17.3464 11.073H18.2026C20.1876 11.073 21.7834 12.7081 21.7834 14.6539V21.777H18.6696V20.8038V16.0549C18.6303 14.8482 17.8134 13.9142 16.6067 13.9142C15.4 13.9142 14.3882 14.8482 14.349 16.0549V21.777H11.2351V11.1895Z"
          className={styles.icon}
        />
      </g>
    </svg>
  )
}

export default LinkedIn
