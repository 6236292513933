export const CUSTOMER_CLASSIFICATION = [
  {
    label: 'Church',
    value: 'Church',
    interest: [
      {
        label: 'Church Music Director',
        value: 'Church Music Director',
        role: [
          { label: 'Adult Choir', value: 'Adult Choir' },
          {
            label: "Children's Choir Director",
            value: "Children's Choir Director",
          },
          { label: 'Praise Team Leader', value: 'Praise Team Leader' },
          { label: 'Handbell Director', value: 'Handbell Director' },
          { label: 'Worship Leader', value: 'Worship Leader' },
          {
            label: 'Church Orchestra Director',
            value: 'Church Orchestra Director',
          },
          {
            label: 'Church Director - Other',
            value: 'Church Director - Other',
          },
        ],
      },
      {
        label: 'Church Musician',
        value: 'Church Musician',
        role: [
          { label: 'Organist', value: 'Organist' },
          { label: 'Pianist', value: 'Pianist' },
          { label: 'Vocalist', value: 'Vocalist' },
          { label: 'Guitarist', value: 'Guitarist' },
          { label: 'Instrumentalist', value: 'Instrumentalist' },
          { label: 'Handbell Ringer', value: 'Handbell Ringer' },
          {
            label: 'Church Musician - Other',
            value: 'Church Musician - Other',
          },
        ],
      },
      { label: 'Staff/Admin', value: 'Staff/Admin' },
    ],

    details: [
      { label: 'Assembly of God', value: 'Assembly Of God' },
      { label: 'Baptist', value: 'Baptist' },
      { label: 'Baptist Southern Baptist', value: 'Baptist Southern Baptist' },
      { label: 'Brethren', value: 'Brethren' },
      { label: 'Catholic', value: 'Catholic' },
      { label: 'Charismatic', value: 'Charismatic' },
      { label: 'Church Of Christ', value: 'Church Of Christ' },
      { label: 'Church Other', value: 'Church Other' },
      { label: 'Congregational', value: 'Congregational' },
      { label: 'Disciples Of Christ', value: 'Disciples Of Christ' },
      { label: 'Episcopal', value: 'Episcopal' },
      { label: 'Evangelical', value: 'Evangelical' },
      { label: 'Holiness', value: 'Holiness' },
      { label: 'LDS Mormon', value: 'LDS Mormon' },
      { label: 'Lutheran', value: 'Lutheran' },
      { label: 'Mennonite', value: 'Mennonite' },
      { label: 'Methodist', value: 'Methodist' },
      { label: 'Moravian', value: 'Moravian' },
      { label: 'Nazarene', value: 'Nazarene' },
      { label: 'Non Denominational', value: 'Non Denominational' },
      { label: 'Orthodox', value: 'Orthodox' },
      { label: 'Pentecostal', value: 'Pentecostal' },
      { label: 'Presbyterian', value: 'Presbyterian' },
      { label: 'Reformed', value: 'Reformed' },
      { label: 'Salvation Army', value: 'Salvation Army' },
      { label: 'Seventh Day Adventist', value: 'Seventh Day Adventist' },
      { label: 'Society Of Friends', value: 'Society Of Friends' },
      { label: 'Unitarian Universalist', value: 'Unitarian Universalist' },
      { label: 'United Church Of Canada', value: 'United Church Of Canada' },
    ],
  },
  {
    label: 'College',
    value: 'College',
    interest: [
      { label: 'Choir Director', value: 'Choir Director' },
      {
        label: 'Band Director',
        value: 'Band Director',
        role: [
          { label: 'Concert Band', value: 'Concert Band' },
          { label: 'Jazz Ensemble', value: 'Jazz Ensemble' },
          { label: 'Marching Band', value: 'Marching Band' },
          { label: 'Band-other', value: 'Band-other' },
        ],
      },
      { label: 'Orchestra Director', value: 'Orchestra Director' },
      { label: 'Department Chairperson', value: 'Department Chairperson' },
      {
        label: 'Special Interest Class',
        value: 'Special Interest Class',
        role: [
          { label: 'Keyboard Class', value: 'Keyboard Class' },
          { label: 'Music Technology', value: 'Music Technology' },
          {
            label: 'Music Education/Methods',
            value: 'Music Education/Methods',
          },
          {
            label: 'Music History/Appreciation',
            value: 'Music History/Appreciation',
          },
          { label: 'Theory/Composition', value: 'Theory/Composition' },
          { label: 'Conducting Instructor', value: 'Conducting Instructor' },
        ],
      },
      { label: 'Musical Theater/Drama', value: 'Musical Theater/Drama' },
      { label: 'Staff/Admin', value: 'Staff/Admin' },
      { label: 'Librarian', value: 'Librarian' },
      { label: 'Bill Payer', value: 'Bill Payer' },
    ],

    details: [
      { label: 'Music Department', value: 'Music Department' },
      { label: 'Acquisitions', value: 'Acquisitions' },
      { label: 'Bookstore', value: 'Bookstore' },
    ],
  },
  {
    label: 'Community Group',
    value: 'Community Group',
    interest: [
      { label: 'Choir Director', value: 'Choir Director' },
      {
        label: 'Band Director',
        value: 'Band Director',
        role: [
          { label: 'Concert Band', value: 'Concert Band' },
          { label: 'Jazz Ensemble', value: 'Jazz Ensemble' },
          { label: 'Marching Band', value: 'Marching Band' },
          { label: 'Band-other', value: 'Band-other' },
        ],
      },
      { label: 'Orchestra Director', value: 'Orchestra Director' },
      { label: 'Staff/Admin', value: 'Staff/Admin' },
    ],
    details: [
      { label: 'Adult', value: 'Adult' },
      { label: "Children's", value: "Children's" },
      { label: 'Music Camp', value: 'Music Camp' },
      { label: 'Other', value: 'Other' },
    ],
  },
  {
    label: 'Fine Arts School',
    value: 'Fine Arts School',
    interest: [
      { label: 'Choir Director', value: 'Choir Director' },
      {
        label: 'Band Director',
        value: 'Band Director',
        role: [
          { label: 'Concert Band', value: 'Concert Band' },
          { label: 'Jazz Ensemble', value: 'Jazz Ensemble' },
          { label: 'Marching Band', value: 'Marching Band' },
          { label: 'Band-other', value: 'Band-other' },
        ],
      },
      { label: 'Orchestra Director', value: 'Orchestra Director' },
      { label: 'General Music', value: 'General Music' },
      { label: 'Department Chairperson', value: 'Department Chairperson' },
      { label: 'Musical Theater/Drama', value: 'Musical Theater/Drama' },
      { label: 'Staff/Admin', value: 'Staff/Admin' },
      { label: 'Bill Payer', value: 'Bill Payer' },
    ],
  },
  {
    label: 'Home School',
    value: 'Home School',
    interest: [
      { label: 'Choir Director', value: 'Choir Director' },
      {
        label: 'Band Director',
        value: 'Band Director',
        role: [
          { label: 'Concert Band', value: 'Concert Band' },
          { label: 'Jazz Ensemble', value: 'Jazz Ensemble' },
          { label: 'Marching Band', value: 'Marching Band' },
          { label: 'Band-other', value: 'Band-other' },
        ],
      },
      { label: 'Orchestra Director', value: 'Orchestra Director' },
      { label: 'General Music', value: 'General Music' },
      { label: 'Department Chairperson', value: 'Department Chairperson' },
      { label: 'Musical Theater/Drama', value: 'Musical Theater/Drama' },
      { label: 'Staff/Admin', value: 'Staff/Admin' },
      { label: 'Bill Payer', value: 'Bill Payer' },
    ],
  },
  {
    label: 'Individual',
    value: 'Individual',
    interest: [
      {
        label: 'Musician',
        value: 'Musician',
        role: [
          { label: 'Organist', value: 'Organist' },
          { label: 'Pianist', value: 'Pianist' },
          { label: 'Vocalist', value: 'Vocalist' },
          { label: 'Guitarist', value: 'Guitarist' },
          { label: 'Woodwind', value: 'Woodwind' },
          { label: 'Brass', value: 'Brass' },
          { label: 'Percussion', value: 'Percussion' },
          { label: 'String', value: 'String' },
        ],
      },
      { label: 'Church Musician', value: 'Church Musician' },
    ],
  },
  {
    label: 'Military',
    value: 'Military',
    interest: [
      {
        label: 'Band Director',
        value: 'Band Director',
        role: [
          { label: 'Concert Band', value: 'Concert Band' },
          { label: 'Jazz Ensemble', value: 'Jazz Ensemble' },
          { label: 'Marching Band', value: 'Marching Band' },
          { label: 'Band-other', value: 'Band-other' },
        ],
      },
      { label: 'Orchestra Director', value: 'Orchestra Director' },
      { label: 'Staff/Admin', value: 'Staff/Admin' },
    ],
  },
  {
    label: 'Private Music Studio',
    value: 'Private Music Studio',
    interest: [
      { label: 'Voice', value: 'Voice' },
      { label: 'Piano', value: 'Piano' },
      { label: 'Woodwind', value: 'Woodwind' },
      { label: 'Brass', value: 'Brass' },
      { label: 'String', value: 'String' },
      { label: 'Percussion', value: 'Percussion' },
      { label: 'Guitar', value: 'Guitar' },
    ],
  },
  {
    label: 'Professional Ensemble',
    value: 'Professional Ensemble',
    interest: [
      { label: 'Choir Director', value: 'Choir Director' },
      {
        label: 'Band Director',
        value: 'Band Director',
        role: [
          { label: 'Concert Band', value: 'Concert Band' },
          { label: 'Jazz Ensemble', value: 'Jazz Ensemble' },
          { label: 'Marching Band', value: 'Marching Band' },
          { label: 'Band-other', value: 'Band-other' },
        ],
      },
      { label: 'Orchestra Director', value: 'Orchestra Director' },
      { label: 'Staff/Admin', value: 'Staff/Admin' },
    ],
  },

  {
    label: 'Public Library',
    value: 'Public Library',
    interest: [
      { label: 'Librarian', value: 'Librarian' },
      { label: 'Choir Director', value: 'Choir Director' },
      {
        label: 'Band Director',
        value: 'Band Director',
        role: [
          { label: 'Concert Band', value: 'Concert Band' },
          { label: 'Jazz Ensemble', value: 'Jazz Ensemble' },
          { label: 'Marching Band', value: 'Marching Band' },
          { label: 'Band-other', value: 'Band-other' },
        ],
      },
    ],
  },
  {
    label: 'School Charter',
    value: 'School Charter',
    interest: [
      { label: 'Choir Director', value: 'Choir Director' },
      {
        label: 'Band Director',
        value: 'Band Director',
        role: [
          { label: 'Concert Band', value: 'Concert Band' },
          { label: 'Jazz Ensemble', value: 'Jazz Ensemble' },
          { label: 'Marching Band', value: 'Marching Band' },
          { label: 'Band-other', value: 'Band-other' },
        ],
      },
      { label: 'Orchestra Director', value: 'Orchestra Director' },
      { label: 'General Music', value: 'General Music' },
      { label: 'Department Chairperson', value: 'Department Chairperson' },
      { label: 'Musical Theater/Drama', value: 'Musical Theater/Drama' },
      { label: 'Staff/Admin', value: 'Staff/Admin' },
      { label: 'Bill Payer', value: 'Bill Payer' },
    ],
    details: [
      { label: 'Elementary (K-6)', value: 'Elementary (K-6)' },
      {
        label: 'Middle or Junior High (6-9)',
        value: 'Middle or Junior High (6-9)',
      },
      { label: 'High School (9-12)', value: 'High School (9-12)' },
      {
        label: 'Junior Senior High (7-12)',
        value: 'Junior Senior High (7-12)',
      },
      { label: 'K-8', value: 'K-8' },
      { label: 'K-12', value: 'K-12' },
    ],
  },
  {
    label: 'School Christian',
    value: 'School Christian',
    interest: [
      { label: 'Choir Director', value: 'Choir Director' },
      {
        label: 'Band Director',
        value: 'Band Director',
        role: [
          { label: 'Concert Band', value: 'Concert Band' },
          { label: 'Jazz Ensemble', value: 'Jazz Ensemble' },
          { label: 'Marching Band', value: 'Marching Band' },
          { label: 'Band-other', value: 'Band-other' },
        ],
      },
      { label: 'Orchestra Director', value: 'Orchestra Director' },
      { label: 'General Music', value: 'General Music' },
      { label: 'Department Chairperson', value: 'Department Chairperson' },
      { label: 'Musical Theater/Drama', value: 'Musical Theater/Drama' },
      { label: 'Staff/Admin', value: 'Staff/Admin' },
      { label: 'Bill Payer', value: 'Bill Payer' },
    ],

    details: [
      { label: 'Elementary (K-6)', value: 'Elementary (K-6)' },
      {
        label: 'Middle or Junior High (6-9)',
        value: 'Middle or Junior High (6-9)',
      },
      { label: 'High School (9-12)', value: 'High School (9-12)' },
      {
        label: 'Junior Senior High (7-12)',
        value: 'Junior Senior High (7-12)',
      },
      { label: 'K-8', value: 'K-8' },
      { label: 'K-12', value: 'K-12' },
    ],
  },

  {
    label: 'School Parochial',
    value: 'School Parochial',
    interest: [
      { label: 'Choir Director', value: 'Choir Director' },
      {
        label: 'Band Director',
        value: 'Band Director',
        role: [
          { label: 'Concert Band', value: 'Concert Band' },
          { label: 'Jazz Ensemble', value: 'Jazz Ensemble' },
          { label: 'Marching Band', value: 'Marching Band' },
          { label: 'Band-other', value: 'Band-other' },
        ],
      },
      { label: 'Orchestra Director', value: 'Orchestra Director' },
      { label: 'General Music', value: 'General Music' },
      { label: 'Department Chairperson', value: 'Department Chairperson' },
      { label: 'Musical Theater/Drama', value: 'Musical Theater/Drama' },
      { label: 'Staff/Admin', value: 'Staff/Admin' },
      { label: 'Bill Payer', value: 'Bill Payer' },
    ],

    details: [
      { label: 'High School (9-12)', value: 'High School (9-12)' },
      { label: 'K-8', value: 'K-8' },
      { label: 'K-12', value: 'K-12' },
    ],
  },
  {
    label: 'School Private',
    value: 'School Private',
    interest: [
      { label: 'Choir Director', value: 'Choir Director' },
      {
        label: 'Band Director',
        value: 'Band Director',
        role: [
          { label: 'Concert Band', value: 'Concert Band' },
          { label: 'Jazz Ensemble', value: 'Jazz Ensemble' },
          { label: 'Marching Band', value: 'Marching Band' },
          { label: 'Band-other', value: 'Band-other' },
        ],
      },
      { label: 'Orchestra Director', value: 'Orchestra Director' },
      { label: 'General Music', value: 'General Music' },
      { label: 'Department Chairperson', value: 'Department Chairperson' },
      { label: 'Musical Theater/Drama', value: 'Musical Theater/Drama' },
      { label: 'Staff/Admin', value: 'Staff/Admin' },
      { label: 'Bill Payer', value: 'Bill Payer' },
    ],

    details: [
      { label: 'Elementary (K-6)', value: 'Elementary (K-6)' },
      {
        label: 'Middle or Junior High (6-9)',
        value: 'Middle or Junior High (6-9)',
      },
      { label: 'High School (9-12)', value: 'High School (9-12)' },
      {
        label: 'Junior Senior High (7-12)',
        value: 'Junior Senior High (7-12)',
      },
      { label: 'K-8', value: 'K-8' },
      { label: 'K-12', value: 'K-12' },
    ],
  },
  {
    label: 'School Public',
    value: 'School Public',
    interest: [
      { label: 'Choir Director', value: 'Choir Director' },
      {
        label: 'Band Director',
        value: 'Band Director',
        role: [
          { label: 'Concert Band', value: 'Concert Band' },
          { label: 'Jazz Ensemble', value: 'Jazz Ensemble' },
          { label: 'Marching Band', value: 'Marching Band' },
          { label: 'Band-other', value: 'Band-other' },
        ],
      },
      { label: 'Orchestra Director', value: 'Orchestra Director' },
      { label: 'General Music', value: 'General Music' },
      { label: 'Department Chairperson', value: 'Department Chairperson' },
      { label: 'Musical Theater/Drama', value: 'Musical Theater/Drama' },
      { label: 'Staff/Admin', value: 'Staff/Admin' },
      { label: 'Bill Payer', value: 'Bill Payer' },
    ],

    details: [
      { label: 'Elementary (K-6)', value: 'Elementary (K-6)' },
      {
        label: 'Middle or Junior High (6-9)',
        value: 'Middle or Junior High (6-9)',
      },
      { label: 'High School (9-12)', value: 'High School (9-12)' },
      {
        label: 'Junior Senior High (7-12)',
        value: 'Junior Senior High (7-12)',
      },
      { label: 'K-8', value: 'K-8' },
      { label: 'K-12', value: 'K-12' },
      {
        label: 'School District (Board of Ed)',
        value: 'School District (Board of Ed)',
      },
    ],
  },
]
export const BILLING_CLASSIFICATION = [
  {
    label: 'Private Music Studio',
    value: 'Private Music Studio',
  },
  {
    label: 'School District/Board of Ed',
    value: 'School District/Board of Ed',
  },
  {
    label: 'School',
    value: 'School',
  },
  {
    label: 'Booster Group',
    value: 'Booster Group',
  },
  {
    label: 'Home School',
    value: 'Home School',
  },
  {
    label: 'Music Department',
    value: 'Music Department',
  },
  {
    label: 'Acquisitions',
    value: 'Acquisitions',
  },
  {
    label: 'Bookstore',
    value: 'Bookstore',
  },
  {
    label: 'Church',
    value: 'Church',
  },
  {
    label: 'Community Group',
    value: 'Community Group',
  },
  {
    label: 'Professional Ensemble',
    value: 'Professional Ensemble',
  },
  {
    label: 'Public Library',
    value: 'Public Library',
  },
  {
    label: 'Military',
    value: 'Military',
  },
  {
    label: 'Other',
    value: 'Other',
  },
]

export const COUNTRIES = [
  { value: 'AFG', label: 'Afghanistan' },
  { value: 'ALB', label: 'Albania' },
  { value: 'DZA', label: 'Algeria' },
  { value: 'AND', label: 'Andorra' },
  { value: 'AGO', label: 'Angola' },
  { value: 'AIA', label: 'Anguilla' },
  { value: 'ATA', label: 'Antarctica' },
  { value: 'ATG', label: 'Antigua and Barbuda' },
  { value: 'ARG', label: 'Argentina' },
  { value: 'ARM', label: 'Armenia' },
  { value: 'ABW', label: 'Aruba' },
  {
    value: 'AUS',
    label: 'Australia',
    states: [
      { value: 'NSW', label: 'New South Wales' },
      { value: 'QLD', label: 'Queensland' },
      { value: 'VIC', label: 'Victoria' },
      { value: 'WA', label: 'Western Australia' },
      { value: 'SA', label: 'South Australia' },
      { value: 'TAS', label: 'Tasmania' },
      { value: 'NT', label: 'Northern Territory' },
      { value: 'ACT', label: 'Australian Capital Territory' },
    ],
  },
  { value: 'AUT', label: 'Austria' },
  { value: 'AZE', label: 'Azerbaijan' },
  { value: 'BHS', label: 'Bahamas' },
  { value: 'BHR', label: 'Bahrain' },
  { value: 'BGD', label: 'Bangladesh' },
  { value: 'BRB', label: 'Barbados' },
  { value: 'BLR', label: 'Belarus' },
  { value: 'BEL', label: 'Belgium' },
  { value: 'BLZ', label: 'Belize' },
  { value: 'BEN', label: 'Benin' },
  { value: 'BMU', label: 'Bermuda' },
  { value: 'BTN', label: 'Bhutan' },
  { value: 'BOL', label: 'Bolivia' },
  { value: 'BIH', label: 'Bosnia and Herzegovina' },
  { value: 'BWA', label: 'Botswana' },
  { value: 'BVT', label: 'Bouvet Island' },
  { value: 'BRA', label: 'Brazil' },
  { value: 'IOT', label: 'British Indian Ocean Territory' },
  { value: 'BRN', label: 'Brunei Darussalam' },
  { value: 'BGR', label: 'Bulgaria' },
  { value: 'BFA', label: 'Burkina Faso' },
  { value: 'BDI', label: 'Burundi' },
  { value: 'KHM', label: 'Cambodia' },
  { value: 'CMR', label: 'Cameroon' },
  {
    value: 'CAN',
    label: 'Canada',
    states: [
      { value: 'AB', label: 'Alberta' },
      { value: 'BC', label: 'British Columbia' },
      { value: 'MB', label: 'Manitoba' },
      { value: 'NB', label: 'New Brunswick' },
      { value: 'NL', label: 'Newfoundland and Labrador' },
      { value: 'NS', label: 'Nova Scotia' },
      { value: 'ON', label: 'Ontario' },
      { value: 'PE', label: 'Prince Edward Island' },
      { value: 'QC', label: 'Quebec' },
      { value: 'SK', label: 'Saskatchewan' },
      { value: 'NT', label: 'Northwest Territories' },
      { value: 'NU', label: 'Nunavut' },
      { value: 'YT', label: 'Yukon' },
    ],
  },
  { value: 'CPV', label: 'Cape Verde' },
  { value: 'CYM', label: 'Cayman Islands' },
  { value: 'CAF', label: 'Central African Republic' },
  { value: 'TCD', label: 'Chad' },
  { value: 'CHL', label: 'Chile' },
  { value: 'CHN', label: 'China' },
  { value: 'CXR', label: 'Christmas Island' },
  { value: 'CCK', label: 'Cocos (Keeling) Islands' },
  { value: 'COL', label: 'Colombia' },
  { value: 'COM', label: 'Comoros' },
  { value: 'COG', label: 'Congo' },
  { value: 'COD', label: 'Democratic Republic of the Congo' },
  { value: 'COK', label: 'Cook Islands' },
  { value: 'CRI', label: 'Costa Rica' },
  { value: 'CIV', label: "Côte d'Ivoire" },
  { value: 'HRV', label: 'Croatia' },
  { value: 'CUB', label: 'Cuba' },
  { value: 'CYP', label: 'Cyprus' },
  { value: 'CZE', label: 'Czech Republic' },
  { value: 'DNK', label: 'Denmark' },
  { value: 'DJI', label: 'Djibouti' },
  { value: 'DMA', label: 'Dominica' },
  { value: 'DOM', label: 'Dominican Republic' },
  { value: 'ECU', label: 'Ecuador' },
  { value: 'EGY', label: 'Egypt' },
  { value: 'SLV', label: 'El Salvador' },
  { value: 'GNQ', label: 'Equatorial Guinea' },
  { value: 'ERI', label: 'Eritrea' },
  { value: 'EST', label: 'Estonia' },
  { value: 'ETH', label: 'Ethiopia' },
  { value: 'FLK', label: 'Falkland Islands (Malvinas)' },
  { value: 'FRO', label: 'Faroe Islands' },
  { value: 'FJI', label: 'Fiji' },
  { value: 'FIN', label: 'Finland' },
  { value: 'FRA', label: 'France' },
  { value: 'GUF', label: 'French Guiana' },
  { value: 'PYF', label: 'French Polynesia' },
  { value: 'ATF', label: 'French Southern Territories' },
  { value: 'GAB', label: 'Gabon' },
  { value: 'GMB', label: 'Gambia' },
  { value: 'GEO', label: 'Georgia' },
  { value: 'DEU', label: 'Germany' },
  { value: 'GHA', label: 'Ghana' },
  { value: 'GIB', label: 'Gibraltar' },
  { value: 'GRC', label: 'Greece' },
  { value: 'GRL', label: 'Greenland' },
  { value: 'GRD', label: 'Grenada' },
  { value: 'GLP', label: 'Guadeloupe' },
  { value: 'GUM', label: 'Guam' },
  { value: 'GTM', label: 'Guatemala' },
  { value: 'GGY', label: 'Guernsey' },
  { value: 'GIN', label: 'Guinea' },
  { value: 'GNB', label: 'Guinea-Bissau' },
  { value: 'GUY', label: 'Guyana' },
  { value: 'HTI', label: 'Haiti' },
  { value: 'HMD', label: 'Heard Island and McDonald Islands' },
  { value: 'VAT', label: 'Holy See (Vatican City State)' },
  { value: 'HND', label: 'Honduras' },
  { value: 'HKG', label: 'Hong Kong' },
  { value: 'HUN', label: 'Hungary' },
  { value: 'ISL', label: 'Iceland' },
  { value: 'IND', label: 'India' },
  { value: 'IDN', label: 'Indonesia' },
  { value: 'IRN', label: 'Iran' },
  { value: 'IRQ', label: 'Iraq' },
  { value: 'IRL', label: 'Ireland' },
  { value: 'IMN', label: 'Isle of Man' },
  { value: 'ISR', label: 'Israel' },
  { value: 'ITA', label: 'Italy' },
  { value: 'JAM', label: 'Jamaica' },
  { value: 'JPN', label: 'Japan' },
  { value: 'JEY', label: 'Jersey' },
  { value: 'JOR', label: 'Jordan' },
  { value: 'KAZ', label: 'Kazakhstan' },
  { value: 'KEN', label: 'Kenya' },
  { value: 'KIR', label: 'Kiribati' },
  { value: 'PRK', label: 'Korea' },
  { value: 'KOR', label: 'Korea' },
  { value: 'KWT', label: 'Kuwait' },
  { value: 'KGZ', label: 'Kyrgyzstan' },
  { value: 'LAO', label: 'Lao' },
  { value: 'LVA', label: 'Latvia' },
  { value: 'LBN', label: 'Lebanon' },
  { value: 'LSO', label: 'Lesotho' },
  { value: 'LBR', label: 'Liberia' },
  { value: 'LBY', label: 'Libya' },
  { value: 'LIE', label: 'Liechtenstein' },
  { value: 'LTU', label: 'Lithuania' },
  { value: 'LUX', label: 'Luxembourg' },
  { value: 'MAC', label: 'Macao' },
  { value: 'MKD', label: 'Macedonia' },
  { value: 'MDG', label: 'Madagascar' },
  { value: 'MWI', label: 'Malawi' },
  { value: 'MYS', label: 'Malaysia' },
  { value: 'MDV', label: 'Maldives' },
  { value: 'MLI', label: 'Mali' },
  { value: 'MLT', label: 'Malta' },
  { value: 'MHL', label: 'Marshall Islands' },
  { value: 'MTQ', label: 'Martinique' },
  { value: 'MRT', label: 'Mauritania' },
  { value: 'MUS', label: 'Mauritius' },
  { value: 'MYT', label: 'Mayotte' },
  { value: 'MEX', label: 'Mexico' },
  { value: 'FSM', label: 'Micronesia' },
  { value: 'MDA', label: 'Moldova' },
  { value: 'MCO', label: 'Monaco' },
  { value: 'MNG', label: 'Mongolia' },
  { value: 'MNE', label: 'Montenegro' },
  { value: 'MSR', label: 'Montserrat' },
  { value: 'MAR', label: 'Morocco' },
  { value: 'MOZ', label: 'Mozambique' },
  { value: 'MMR', label: 'Myanmar' },
  { value: 'NAM', label: 'Namibia' },
  { value: 'NRU', label: 'Nauru' },
  { value: 'NPL', label: 'Nepal' },
  { value: 'NLD', label: 'Netherlands' },
  { value: 'ANT', label: 'Netherlands Antilles' },
  { value: 'NCL', label: 'New Caledonia' },
  { value: 'NZL', label: 'New Zealand' },
  { value: 'NIC', label: 'Nicaragua' },
  { value: 'NER', label: 'Niger' },
  { value: 'NGA', label: 'Nigeria' },
  { value: 'NIU', label: 'Niue' },
  { value: 'NFK', label: 'Norfolk Island' },
  { value: 'MNP', label: 'Northern Mariana Islands' },
  { value: 'NOR', label: 'Norway' },
  { value: 'OMN', label: 'Oman' },
  { value: 'PAK', label: 'Pakistan' },
  { value: 'PLW', label: 'Palau' },
  { value: 'PSE', label: 'Palestinian Territory' },
  { value: 'PAN', label: 'Panama' },
  { value: 'PNG', label: 'Papua New Guinea' },
  { value: 'PRY', label: 'Paraguay' },
  { value: 'PER', label: 'Peru' },
  { value: 'PHL', label: 'Philippines' },
  { value: 'PCN', label: 'Pitcairn' },
  { value: 'POL', label: 'Poland' },
  { value: 'PRT', label: 'Portugal' },
  { value: 'PRI', label: 'Puerto Rico' },
  { value: 'QAT', label: 'Qatar' },
  { value: 'REU', label: 'Réunion' },
  { value: 'ROU', label: 'Romania' },
  { value: 'RUS', label: 'Russian Federation' },
  { value: 'RWA', label: 'Rwanda' },
  { value: 'BLM', label: 'Saint Barthélemy' },
  { value: 'SHN', label: 'Saint Helena' },
  { value: 'KNA', label: 'Saint Kitts and Nevis' },
  { value: 'LCA', label: 'Saint Lucia' },
  { value: 'MAF', label: 'Saint Martin (French part)' },
  { value: 'SPM', label: 'Saint Pierre and Miquelon' },
  { value: 'VCT', label: 'Saint Vincent and the Grenadines' },
  { value: 'WSM', label: 'Samoa' },
  { value: 'SMR', label: 'San Marino' },
  { value: 'STP', label: 'Sao Tome and Principe' },
  { value: 'SAU', label: 'Saudi Arabia' },
  { value: 'SEN', label: 'Senegal' },
  { value: 'SRB', label: 'Serbia' },
  { value: 'SYC', label: 'Seychelles' },
  { value: 'SLE', label: 'Sierra Leone' },
  { value: 'SGP', label: 'Singapore' },
  { value: 'SVK', label: 'Slovakia' },
  { value: 'SVN', label: 'Slovenia' },
  { value: 'SLB', label: 'Solomon Islands' },
  { value: 'SOM', label: 'Somalia' },
  { value: 'ZAF', label: 'South Africa' },
  { value: 'SGS', label: 'South Georgia and the South Sandwich Islands' },
  { value: 'ESP', label: 'Spain' },
  { value: 'LKA', label: 'Sri Lanka' },
  { value: 'SDN', label: 'Sudan' },
  { value: 'SUR', label: 'Suriname' },
  { value: 'SJM', label: 'Svalbard and Jan Mayen' },
  { value: 'SWZ', label: 'Swaziland' },
  { value: 'SWE', label: 'Sweden' },
  { value: 'CHE', label: 'Switzerland' },
  { value: 'SYR', label: 'Syrian Arab Republic' },
  { value: 'TWN', label: 'Taiwan' },
  { value: 'TJK', label: 'Tajikistan' },
  { value: 'TZA', label: 'Tanzania' },
  { value: 'THA', label: 'Thailand' },
  { value: 'TLS', label: 'Timor-Leste' },
  { value: 'TGO', label: 'Togo' },
  { value: 'TKL', label: 'Tokelau' },
  { value: 'TON', label: 'Tonga' },
  { value: 'TTO', label: 'Trinidad and Tobago' },
  { value: 'TUN', label: 'Tunisia' },
  { value: 'TUR', label: 'Turkey' },
  { value: 'TKM', label: 'Turkmenistan' },
  { value: 'TCA', label: 'Turks and Caicos Islands' },
  { value: 'TUV', label: 'Tuvalu' },
  { value: 'UGA', label: 'Uganda' },
  { value: 'UKR', label: 'Ukraine' },
  { value: 'ARE', label: 'United Arab Emirates' },
  {
    value: 'GBR',
    label: 'United Kingdom',
    states: [
      { value: 'ENG', label: 'England' },
      { value: 'NIR', label: 'Northern Ireland' },
      { value: 'SCT', label: 'Scotland' },
      { value: 'WLS', label: 'Wales' },
    ],
  },
  {
    value: 'USA',
    label: 'United States',
    states: [
      { value: 'AL', label: 'Alabama' },
      { value: 'AK', label: 'Alaska' },
      { value: 'AZ', label: 'Arizona' },
      { value: 'AR', label: 'Arkansas' },
      { value: 'CA', label: 'California' },
      { value: 'CO', label: 'Colorado' },
      { value: 'CT', label: 'Connecticut' },
      { value: 'DE', label: 'Delaware' },
      { value: 'DC', label: 'District of Columbia' },
      { value: 'FL', label: 'Florida' },
      { value: 'GA', label: 'Georgia' },
      { value: 'HI', label: 'Hawaii' },
      { value: 'ID', label: 'Idaho' },
      { value: 'IL', label: 'Illinois' },
      { value: 'IN', label: 'Indiana' },
      { value: 'IA', label: 'Iowa' },
      { value: 'KS', label: 'Kansas' },
      { value: 'KY', label: 'Kentucky' },
      { value: 'LA', label: 'Louisiana' },
      { value: 'ME', label: 'Maine' },
      { value: 'MD', label: 'Maryland' },
      { value: 'MA', label: 'Massachusetts' },
      { value: 'MI', label: 'Michigan' },
      { value: 'MN', label: 'Minnesota' },
      { value: 'MS', label: 'Mississippi' },
      { value: 'MO', label: 'Missouri' },
      { value: 'MT', label: 'Montana' },
      { value: 'NE', label: 'Nebraska' },
      { value: 'NV', label: 'Nevada' },
      { value: 'NH', label: 'New Hampshire' },
      { value: 'NJ', label: 'New Jersey' },
      { value: 'NM', label: 'New Mexico' },
      { value: 'NY', label: 'New York' },
      { value: 'NC', label: 'North Carolina' },
      { value: 'ND', label: 'North Dakota' },
      { value: 'OH', label: 'Ohio' },
      { value: 'OK', label: 'Oklahoma' },
      { value: 'OR', label: 'Oregon' },
      { value: 'PA', label: 'Pennsylvania' },
      { value: 'RI', label: 'Rhode Island' },
      { value: 'SC', label: 'South Carolina' },
      { value: 'SD', label: 'South Dakota' },
      { value: 'TN', label: 'Tennessee' },
      { value: 'TX', label: 'Texas' },
      { value: 'UT', label: 'Utah' },
      { value: 'VT', label: 'Vermont' },
      { value: 'VA', label: 'Virginia' },
      { value: 'WA', label: 'Washington' },
      { value: 'WV', label: 'West Virginia' },
      { value: 'WI', label: 'Wisconsin' },
      { value: 'WY', label: 'Wyoming' },
    ],
  },
  { value: 'URY', label: 'Uruguay' },
  { value: 'UZB', label: 'Uzbekistan' },
  { value: 'VUT', label: 'Vanuatu' },
  { value: 'VEN', label: 'Venezuela' },
  { value: 'VNM', label: 'Vietnam' },
  { value: 'VGB', label: 'Virgin Islands, British' },
  { value: 'VIR', label: 'Virgin Islands, U.S.' },
  { value: 'WLF', label: 'Wallis and Futuna' },
  { value: 'ESH', label: 'Western Sahara' },
  { value: 'YEM', label: 'Yemen' },
  { value: 'ZMB', label: 'Zambia' },
  { value: 'ZWE', label: 'Zimbabwe' },
]
