import { colors } from '../../../../constants/colors'

const MyScoreFull = () => {
  return (
    <svg
      width="85"
      height="22"
      viewBox="0 0 85 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1283 16.7089H11.3682L8.9191 6.46476V16.7258H8.23926V5.14307H9.33292L11.7652 15.3872L14.1805 5.14307H15.2742V16.7258H14.5479V6.4352L12.1325 16.7131L12.1283 16.7089Z"
        fill={`var(${colors.badgeTurquoiseDark.token})`}
      />
      <path
        d="M73.6609 0H11.3389C5.2667 0 0.338867 4.92361 0.338867 11C0.338867 17.0722 5.26247 22 11.3389 22H73.6609C79.7331 22 84.6609 17.0764 84.6609 11C84.6609 4.92361 79.7373 0 73.6609 0ZM12.044 20.2223C6.93887 20.2223 2.80067 16.0841 2.80067 10.9789C2.80067 5.8737 6.93887 1.73551 12.044 1.73551C17.1492 1.73551 21.2874 5.8737 21.2874 10.9789C21.2874 16.0841 17.1492 20.2223 12.044 20.2223Z"
        fill={`var(${colors.badgeTurquoiseDark.token})`}
      />
      <path
        d="M28.9897 14.6695L30.645 6.19043L33.3897 12.3133L36.1556 6.19043L37.6462 14.6695H36.4174L35.6151 9.91481L33.3222 15.0327L31.0504 9.90214L30.2228 14.6653H28.994L28.9897 14.6695Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M40.5555 13.8882L38.4272 9.69092H39.8207L41.2395 12.6003L42.6837 9.69092H43.9842L40.0234 17.3761H38.7228L40.5555 13.8882Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M51.1586 8.53415C51.0868 8.3568 50.9813 8.19212 50.8039 8.03588C50.5548 7.83319 50.2972 7.75296 49.9509 7.75296C49.2162 7.75296 48.8404 8.19212 48.8404 8.69884C48.8404 8.9353 48.9248 9.34912 49.6934 9.65738L50.4872 9.9783C51.9398 10.5695 52.3452 11.3971 52.3452 12.2965C52.3452 13.7998 51.2811 14.8259 49.8031 14.8259C48.891 14.8259 48.3506 14.4839 47.9452 14.032C47.5187 13.5591 47.3287 13.0397 47.2822 12.495L48.4772 12.2332C48.4772 12.6217 48.6208 12.989 48.8066 13.2382C49.0304 13.5211 49.364 13.7111 49.8369 13.7111C50.5717 13.7111 51.1375 13.1791 51.1375 12.3852C51.1375 11.5914 50.521 11.2493 50.0016 11.0382L49.2458 10.7173C48.5955 10.447 47.6369 9.90229 47.6369 8.71995C47.6369 7.65584 48.4646 6.63818 49.9425 6.63818C50.7955 6.63818 51.2768 6.9591 51.526 7.17024C51.7371 7.36026 51.9651 7.6305 52.1298 7.96409L51.1586 8.52993V8.53415Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M57.4966 11.2197C56.9646 10.7003 56.4452 10.6032 56.0905 10.6032C55.5458 10.6032 55.2037 10.827 54.9926 11.0424C54.7815 11.2577 54.5661 11.6336 54.5661 12.1909C54.5661 12.7483 54.7899 13.1621 55.0137 13.3859C55.2755 13.6351 55.6387 13.7871 56.0652 13.7871C56.5381 13.7871 57.0955 13.5971 57.4966 13.1495V14.4374C57.2137 14.6147 56.7535 14.8259 55.9849 14.8259C55.1573 14.8259 54.5661 14.5894 54.0932 14.0911C53.7385 13.7238 53.3838 13.1326 53.3838 12.2247C53.3838 11.3169 53.7723 10.6623 54.1396 10.2992C54.5281 9.91071 55.1573 9.56445 56.137 9.56445C56.517 9.56445 56.9646 9.62357 57.4966 9.91916V11.2197Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M63.6701 12.1865C63.6701 13.7109 62.5131 14.8215 61.0099 14.8215C59.5066 14.8215 58.3496 13.7109 58.3496 12.1865C58.3496 10.6622 59.5066 9.56006 61.0099 9.56006C62.5131 9.56006 63.6701 10.6495 63.6701 12.1865ZM62.4878 12.1992C62.4878 11.0633 61.7446 10.6031 61.0099 10.6031C60.2751 10.6031 59.532 11.0633 59.532 12.1992C59.532 13.1578 60.0978 13.7827 61.0099 13.7827C61.922 13.7827 62.4878 13.1578 62.4878 12.1992Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M64.8735 9.69096H66.0305V10.1386C66.1614 9.98655 66.3515 9.81764 66.5161 9.72474C66.7399 9.59384 66.9637 9.56006 67.2256 9.56006C67.5085 9.56006 67.8167 9.60651 68.1376 9.79653L67.6647 10.848C67.4029 10.6833 67.1918 10.6706 67.0735 10.6706C66.8244 10.6706 66.5753 10.7044 66.3515 10.9409C66.0305 11.2829 66.0305 11.7558 66.0305 12.0894V14.6652H64.8735V9.68674V9.69096Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M72.8923 13.5337C72.6812 13.9138 72.3729 14.2431 72.052 14.4458C71.6509 14.7076 71.1188 14.8259 70.5867 14.8259C69.9238 14.8259 69.3791 14.6612 68.9061 14.1883C68.4332 13.7153 68.1714 13.0312 68.1714 12.2838C68.1714 11.5364 68.4416 10.7721 68.9652 10.2401C69.3791 9.82626 69.9238 9.56445 70.6332 9.56445C71.427 9.56445 71.9338 9.90649 72.2294 10.2147C72.867 10.8777 72.9261 11.7982 72.9261 12.3176V12.4612H69.3537C69.3791 12.8159 69.5311 13.217 69.7802 13.4662C70.0505 13.7364 70.3841 13.7871 70.667 13.7871C70.9879 13.7871 71.2244 13.7153 71.4608 13.538C71.6635 13.386 71.8155 13.1833 71.9211 12.9932L72.8923 13.538V13.5337ZM71.7438 11.5111C71.6973 11.2408 71.5664 11.0044 71.389 10.8355C71.237 10.6919 70.9879 10.5526 70.6079 10.5526C70.2067 10.5526 69.9449 10.7172 69.7929 10.8735C69.6282 11.0255 69.4973 11.262 69.4382 11.5111H71.7438Z"
        fill={`var(${colors.white.token})`}
      />
    </svg>
  )
}

export default MyScoreFull
