export const colors = {
  black: {
    hexCode: '#1c1c1c',
    token: '--jwp-color-black',
  },
  blackAccent: {
    hexCode: '#3d3d3d',
    token: '--jwp-color-black-accent',
  },
  white: {
    hexCode: '#ffffff',
    token: '--jwp-color-white',
  },
  grey: {
    hexCode: '#e0dadb',
    token: '--jwp-color-grey',
  },
  lightGrey: {
    hexCode: '#f6f4f5',
    token: '--jwp-color-light-grey',
  },
  mediumGrey: {
    hexCode: '#e5e5e5',
    token: '--jwp-color-medium-grey',
  },
  darkGrey: {
    hexCode: '#423f47',
    token: '--jwp-color-dark-grey',
  },
  maroon: {
    hexCode: '#630a22',
    token: '--jwp-color-maroon',
  },
  maroonAccent: {
    hexCode: '#8c0d30',
    token: '--jwp-color-maroon-accent',
  },
  maroonFaded: {
    hexCode: '#866e77',
    token: '--jwp-color-maroon-faded',
  },
  green: {
    hexCode: '#00835c',
    token: '--jwp-color-green',
  },
  greenAccent: {
    hexCode: '#019e70',
    token: '--jwp-color-green-accent',
  },
  plum: {
    hexCode: '#552a6b',
    token: '--jwp-color-plum',
  },
  navy: {
    hexCode: '#1b3650',
    token: '--jwp-color-navy',
  },
  crimson: {
    hexCode: '#75202c',
    token: '--jwp-color-crimson',
  },
  forestGreen: {
    hexCode: '#4a6740',
    token: '--jwp-color-forest-green',
  },
  purple: {
    hexCode: '#5e2ac4',
    token: '--jwp-color-purple',
  },
  tan: {
    hexCode: '#d2bc91',
    token: '--jwp-color-tan',
  },
  gold: {
    hexCode: '#ffd98f',
    token: '--jwp-color-gold',
  },
  yellow: {
    hexCode: '#ffb338',
    token: '--jwp-color-yellow',
  },
  blurple: {
    hexCode: '#7887f7',
    token: '--jwp-color-blurple',
  },
  badgeRed: {
    hexCode: '#ed1c24',
    token: '--jwp-color-badge-red',
  },
  badgeRedEditor: {
    hexCode: '#ec2227',
    token: '--jwp-color-badge-red-tertiary',
  },
  badgeBlue: {
    hexCode: '#2666c2',
    token: '--jwp-color-badge-blue',
  },
  badgeTurquoiseLight: {
    hexCode: '#208182',
    token: '--jwp-color-badge-turquoise-light',
  },
  badgeTurquoiseDark: {
    hexCode: '#133e4f',
    token: '--jwp-color-badge-turquoise-dark',
  },
  badgeBlack: {
    hexCode: '#282828',
    token: '--jwp-color-badge-black',
  },
  pageBlue: {
    hexCode: '#2058e5',
    token: '--jwp-color-page-blue',
  },
  myScoreOrange: {
    hexCode: '#eb8f2c',
    token: '--jwp-color-myscore-orange',
  },
  myScoreBlue: {
    hexCode: '#34638a',
    token: '--jwp-color-myscore-blue',
  },
}
