import type { IconProps } from '../../../typings/props'

const TrackOrderIcon = ({ className, title }: IconProps) => {
  return (
    <svg
      className={className}
      fill="currentColor"
      width="29"
      height="29"
      viewBox="0 0 29 29"
      xmlns="http://www.w3.org/2000/svg"
    >
      {title ? <title>{title}</title> : null}

      <path d="M26.76 13.41H28.26C28.38 13.41 28.5 13.53 28.5 13.65V14.85C28.5 14.97 28.38 15.09 28.26 15.09H26.76C26.34 21.33 21.33 26.34 15.09 26.76V28.26C15.09 28.38 14.97 28.5 14.85 28.5H13.65C13.53 28.5 13.41 28.38 13.41 28.26V26.76C7.17 26.34 2.16 21.33 1.74 15.09H0.24C0.12 15.09 0 14.97 0 14.85V13.65C0 13.53 0.12 13.41 0.24 13.41H1.74C2.16 7.17 7.17 2.16 13.41 1.74V0.24C13.41 0.12 13.53 0 13.65 0H14.85C14.97 0 15.09 0.12 15.09 0.24V1.74C21.33 2.16 26.34 7.17 26.76 13.41ZM15.09 23.61V25.08C20.4 24.69 24.69 20.4 25.08 15.09H23.61C23.49 15.09 23.37 14.97 23.37 14.85V13.65C23.37 13.53 23.49 13.41 23.61 13.41H25.08C24.69 8.1 20.43 3.84 15.09 3.42V4.89C15.09 5.01 14.97 5.13 14.85 5.13H13.65C13.53 5.13 13.41 5.01 13.41 4.89V3.42C8.1 3.81 3.84 8.07 3.42 13.41H4.89C5.01 13.41 5.13 13.53 5.13 13.65V14.85C5.13 14.97 5.01 15.09 4.89 15.09H3.42C3.81 20.4 8.07 24.66 13.41 25.08V23.61C13.41 23.49 13.53 23.37 13.65 23.37H14.85C14.97 23.37 15.09 23.49 15.09 23.61ZM14.6095 6.80969L20.6095 9.53969C20.9095 9.68969 21.0895 9.98969 21.1195 10.3197V18.1797C21.1195 18.5097 20.9395 18.7797 20.6395 18.9297L14.6395 21.6597C14.5195 21.7197 14.3995 21.7497 14.2795 21.7497C14.1595 21.7497 14.0395 21.7197 13.9195 21.6597L7.91945 18.9297C7.61945 18.8097 7.43945 18.5097 7.43945 18.1797V10.2897C7.43945 9.95969 7.61945 9.68969 7.91945 9.53969L13.9195 6.80969C14.1295 6.71969 14.3995 6.71969 14.6095 6.80969ZM18.2395 10.3197L14.2495 8.48969L10.2595 10.3197L14.2495 12.1497L18.2395 10.3197ZM13.4395 13.5897L9.08945 11.6097V17.6697L13.4395 19.6497V13.5897ZM15.0895 13.5897V19.6497L19.4395 17.6697V11.6097L15.0895 13.5897Z" />
    </svg>
  )
}

export default TrackOrderIcon
