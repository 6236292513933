const ExtraPartsIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 20.4342C4.48602 20.4342 0 15.9482 0 10.4342C0 4.92022 4.48602 0.434204 10 0.434204C15.514 0.434204 20 4.92022 20 10.4342C20 15.9482 15.514 20.4342 10 20.4342ZM10 1.48684C5.06641 1.48684 1.05263 5.50061 1.05263 10.4342C1.05263 15.3678 5.06641 19.3816 10 19.3816C14.9336 19.3816 18.9474 15.3678 18.9474 10.4342C18.9474 5.50061 14.9336 1.48684 10 1.48684ZM9.99995 16.6453C9.70924 16.6453 9.47363 16.4097 9.47363 16.119V10.9605H4.31635C4.02565 10.9605 3.79004 10.7249 3.79004 10.4342C3.79004 10.1434 4.02565 9.90784 4.31635 9.90784H9.47363V4.75056C9.47363 4.45985 9.70924 4.22424 9.99995 4.22424C10.2907 4.22424 10.5263 4.45985 10.5263 4.75056V9.90784H15.6848C15.9755 9.90784 16.2111 10.1434 16.2111 10.4342C16.2111 10.7249 15.9755 10.9605 15.6848 10.9605H10.5263V16.119C10.5263 16.4097 10.2907 16.6453 9.99995 16.6453Z" />
    </svg>
  )
}

export default ExtraPartsIcon
