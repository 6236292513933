const FullArrowRightIcon = () => {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.5813 4.40501L8.26525 0.82782C7.91619 0.451274 7.34273 0.451274 6.9812 0.82782C6.63214 1.20437 6.63214 1.82298 6.9812 2.21297L8.73897 4.10915H0.91005C0.411392 4.10915 0 4.55294 0 5.09086C0 5.62879 0.411392 6.07257 0.91005 6.07257H8.75144L6.9812 7.96876C6.63214 8.3453 6.63214 8.96391 6.9812 9.35391C7.15573 9.54218 7.3926 9.63632 7.61699 9.63632C7.85385 9.63632 8.07825 9.54218 8.25278 9.35391L11.5564 5.79016C11.7309 5.60189 11.8182 5.35983 11.8182 5.10431C11.8182 4.8488 11.7434 4.57984 11.5813 4.40501Z" />
    </svg>
  )
}

export default FullArrowRightIcon
