import { colors } from '../../../constants/colors'

const Over145YearsLogo = () => {
  return (
    <svg
      width="289"
      height="14"
      viewBox="0 0 289 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8042 7.33013C11.8042 10.6392 9.32438 13.1727 5.94626 13.1727C2.56814 13.1727 0.0882874 10.6392 0.0882874 7.33013C0.0882874 4.02111 2.56814 1.48752 5.94626 1.48752C9.32438 1.48752 11.8042 4.02111 11.8042 7.33013ZM10.0845 7.33013C10.0845 4.83493 8.36468 3.07677 5.94626 3.07677C3.52783 3.07677 1.80806 4.83493 1.80806 7.33013C1.80806 9.82533 3.52783 11.5835 5.94626 11.5835C8.36468 11.5835 10.0845 9.82533 10.0845 7.33013Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M17.5777 1.69482L20.7178 9.54128L23.858 1.69482H25.7159L20.7178 13.6795L15.7198 1.69482H17.5777Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M36.7255 3.31479H32.2342V6.17083H36.5873V7.79079H32.2342V11.3378H36.7255V12.9578H30.5144V1.69482H36.7255V3.31479Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M44.3417 1.69482C45.7083 1.69482 46.5835 1.86373 47.3129 2.35509C48.5413 3.18427 48.6488 4.51248 48.6488 5.00385C48.6488 6.62381 47.6507 7.80615 46.1536 8.14396L49.6622 12.9578H47.5893L44.3647 8.35125H44.0576V12.9578H42.3378V1.69482H44.3493H44.3417ZM44.0576 6.86181H44.595C45.071 6.86181 46.9904 6.80807 46.9904 5.0192C46.9904 3.42995 45.4856 3.28408 44.6411 3.28408H44.0499V6.86181H44.0576Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M64.9021 3.28408H62.9443L63.8887 1.69482H66.6219V12.9578H64.9021V3.28408Z"
        fill={`var(${colors.tan.token})`}
      />
      <path
        d="M78.6756 9.24185H79.8886V10.8311H78.6756V12.9578H77.0172V10.8311H71.451L78.6756 0.873322V9.24952V9.24185ZM77.0172 9.27255V5.64108L74.4529 9.27255H77.0172Z"
        fill={`var(${colors.tan.token})`}
      />
      <path
        d="M92.1344 3.2841H88.6564L88.096 5.15742C88.2649 5.14207 88.4491 5.12671 88.6181 5.12671C89.5624 5.12671 90.5221 5.44917 91.2207 6.12479C91.7582 6.64687 92.3186 7.54514 92.3186 9.01155C92.3186 10.2476 91.9271 11.238 91.167 11.9981C90.2687 12.8734 89.1939 13.1497 88.0422 13.1497C87.382 13.1497 86.6065 13.0653 85.8848 12.643C85.7159 12.5432 85.071 12.1517 84.549 11.3455L85.8465 10.263C85.8772 10.3474 86.1843 10.9002 86.7063 11.238C87.0749 11.4914 87.6046 11.6296 88.1728 11.6296C89.0864 11.6296 89.6085 11.2764 89.8925 11.0077C90.2303 10.6699 90.6219 10.0634 90.6219 9.10368C90.6219 8.14399 90.2994 7.62191 89.9463 7.27642C89.3858 6.75435 88.6488 6.65454 88.1037 6.65454C87.6123 6.65454 87.2438 6.72364 86.7908 6.89255C86.4376 7.03074 86.1152 7.17662 85.7927 7.39926L87.4664 1.71021H92.1267V3.29946L92.1344 3.2841Z"
        fill={`var(${colors.tan.token})`}
      />
      <path
        d="M108.058 8.09022L104.357 1.69482H106.315L108.917 6.20154L111.52 1.69482H113.478L109.785 8.09022V12.9501H108.065V8.09022H108.058Z"
        fill={`var(${colors.tan.token})`}
      />
      <path
        d="M124.411 3.31479H119.919V6.17083H124.273V7.79079H119.919V11.3378H124.411V12.9578H118.2V1.69482H124.411V3.31479Z"
        fill={`var(${colors.tan.token})`}
      />
      <path
        d="M136.633 10.24H131.82L130.591 12.9578H128.733L134.338 1.04227L139.674 12.9578H137.816L136.633 10.24ZM135.942 8.62L134.269 4.80426L132.534 8.62H135.942Z"
        fill={`var(${colors.tan.token})`}
      />
      <path
        d="M146.484 1.69482C147.85 1.69482 148.725 1.86373 149.455 2.35509C150.691 3.18427 150.791 4.51248 150.791 5.00385C150.791 6.62381 149.793 7.80615 148.296 8.14396L151.804 12.9578H149.731L146.507 8.35125H146.2V12.9578H144.48V1.69482H146.491H146.484ZM146.2 6.86181H146.737C147.213 6.86181 149.132 6.80807 149.132 5.0192C149.132 3.42995 147.628 3.28408 146.783 3.28408H146.192V6.86181H146.2Z"
        fill={`var(${colors.tan.token})`}
      />
      <path
        d="M161.724 4.19003C161.624 3.93667 161.47 3.69866 161.217 3.48369C160.864 3.19962 160.487 3.07678 160.004 3.07678C158.96 3.07678 158.415 3.69866 158.415 4.42803C158.415 4.76584 158.53 5.35701 159.628 5.79463L160.756 6.24761C162.837 7.09214 163.405 8.27448 163.405 9.55663C163.405 11.6987 161.885 13.1728 159.773 13.1728C158.476 13.1728 157.701 12.6814 157.125 12.0442C156.518 11.3685 156.249 10.6238 156.18 9.84837L157.885 9.47985C157.885 10.0403 158.084 10.5624 158.361 10.9156C158.683 11.3225 159.152 11.5912 159.827 11.5912C160.871 11.5912 161.685 10.8311 161.685 9.7025C161.685 8.5739 160.81 8.08254 160.065 7.77544L158.983 7.32246C158.054 6.93091 156.687 6.15548 156.687 4.46642C156.687 2.94626 157.869 1.49521 159.981 1.49521C161.194 1.49521 161.885 1.94818 162.246 2.25528C162.553 2.524 162.868 2.91555 163.106 3.38388L161.724 4.19003Z"
        fill={`var(${colors.tan.token})`}
      />
      <path
        d="M187.436 7.33013C187.436 10.6392 184.956 13.1727 181.578 13.1727C178.2 13.1727 175.72 10.6392 175.72 7.33013C175.72 4.02111 178.2 1.48752 181.578 1.48752C184.956 1.48752 187.436 4.02111 187.436 7.33013ZM185.716 7.33013C185.716 4.83493 183.996 3.07677 181.578 3.07677C179.159 3.07677 177.44 4.83493 177.44 7.33013C177.44 9.82533 179.159 11.5835 181.578 11.5835C183.996 11.5835 185.716 9.82533 185.716 7.33013Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M198.361 3.31479H194.445V6.17083H198.223V7.79079H194.445V12.9578H192.725V1.69482H198.361V3.31479Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M215.866 4.19003C215.766 3.93667 215.612 3.69866 215.359 3.48369C215.006 3.19962 214.63 3.07678 214.146 3.07678C213.102 3.07678 212.557 3.69866 212.557 4.42803C212.557 4.76584 212.672 5.35701 213.77 5.79463L214.898 6.24761C216.979 7.09214 217.547 8.27448 217.547 9.55663C217.547 11.6987 216.027 13.1728 213.916 13.1728C212.618 13.1728 211.843 12.6814 211.267 12.0442C210.66 11.3685 210.392 10.6238 210.322 9.84837L212.027 9.47985C212.027 10.0403 212.226 10.5624 212.503 10.9156C212.825 11.3225 213.294 11.5912 213.969 11.5912C215.013 11.5912 215.827 10.8311 215.827 9.7025C215.827 8.5739 214.952 8.08254 214.207 7.77544L213.125 7.32246C212.196 6.93091 210.829 6.15548 210.829 4.46642C210.829 2.94626 212.011 1.49521 214.123 1.49521C215.336 1.49521 216.027 1.94818 216.388 2.25528C216.695 2.524 217.01 2.91555 217.248 3.38388L215.866 4.19003Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M229.309 3.31479H224.818V6.17083H229.171V7.79079H224.818V11.3378H229.309V12.9578H223.098V1.69482H229.309V3.31479Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M236.917 1.69482C238.284 1.69482 239.159 1.86373 239.889 2.35509C241.125 3.18427 241.225 4.51248 241.225 5.00385C241.225 6.62381 240.226 7.80615 238.729 8.14396L242.238 12.9578H240.165L236.94 8.35125H236.633V12.9578H234.914V1.69482H236.925H236.917ZM236.633 6.86181H237.171C237.647 6.86181 239.566 6.80807 239.566 5.0192C239.566 3.42995 238.061 3.28408 237.217 3.28408H236.626V6.86181H236.633Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M247.873 1.69482L251.013 9.54128L254.154 1.69482H256.011L251.013 13.6795L246.015 1.69482H247.873Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M262.53 1.69482V12.9578H260.81V1.69482H262.53Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M276.948 4.32822C275.697 3.19962 274.537 3.07678 273.892 3.07678C271.443 3.07678 269.793 4.881 269.793 7.36085C269.793 9.8407 271.512 11.5835 273.916 11.5835C275.267 11.5835 276.311 10.8925 276.956 10.286V12.3282C275.827 13.0038 274.645 13.1728 273.869 13.1728C271.827 13.1728 270.545 12.2438 269.885 11.6219C268.572 10.3935 268.081 8.95778 268.081 7.36853C268.081 5.29559 268.94 3.85989 269.885 2.96162C271.052 1.86373 272.403 1.49521 273.954 1.49521C274.983 1.49521 275.981 1.67947 276.956 2.30903V4.3359L276.948 4.32822Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M288.664 3.31479H284.173V6.17083H288.526V7.79079H284.173V11.3378H288.664V12.9578H282.453V1.69482H288.664V3.31479Z"
        fill={`var(${colors.white.token})`}
      />
    </svg>
  )
}

export default Over145YearsLogo
