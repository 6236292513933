const VideoIcon = () => {
  return (
    <svg
      width="35"
      height="22"
      viewBox="0 0 35 22"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M17.574 9.61466L12.1465 6.21098C11.7172 5.935 11.1652 5.93501 10.7359 6.18032C10.276 6.42563 10 6.88559 10 7.40688V14.1836C10 14.7049 10.276 15.1648 10.7359 15.4101C10.9506 15.5328 11.1959 15.5941 11.4105 15.5941C11.6559 15.5941 11.9318 15.5328 12.1465 15.3795L17.574 11.9758C17.9726 11.7305 18.2179 11.2705 18.2179 10.7799C18.2179 10.3199 17.9726 9.89063 17.574 9.61466ZM16.8687 10.8719L11.4412 14.2756C11.4412 14.2756 11.4105 14.3062 11.3492 14.2756C11.3186 14.2449 11.3185 14.2142 11.3185 14.2142V7.43753C11.3185 7.43753 11.3186 7.3762 11.3492 7.3762C11.3799 7.3762 11.3799 7.3762 11.3799 7.3762C11.4105 7.3762 11.4105 7.3762 11.4105 7.3762L16.838 10.7799C16.838 10.7799 16.8687 10.8105 16.8687 10.8412C16.8994 10.8719 16.8687 10.8719 16.8687 10.8719Z"
        fill="currentColor"
      />
      <path
        d="M1 4C1 2.34315 2.34315 1 4 1H24C25.6569 1 27 2.34315 27 4V18C27 19.6569 25.6569 21 24 21H4C2.34315 21 1 19.6569 1 18V4Z"
        strokeWidth="1.35"
        stroke="currentColor"
      />
      <path
        d="M27 9.18885C27 8.88852 27.135 8.6041 27.3676 8.41419L32.3676 4.33255C33.0207 3.79939 34 4.26411 34 5.10721V16.8928C34 17.7359 33.0207 18.2006 32.3676 17.6674L27.3676 13.5858C27.135 13.3959 27 13.1115 27 12.8112V9.18885Z"
        strokeWidth="1.35"
        stroke="currentColor"
      />
    </svg>
  )
}

export default VideoIcon
