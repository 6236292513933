import { colors } from '../../../../constants/colors'

const BasicLibraryFull = () => {
  return (
    <svg
      width="101"
      height="22"
      viewBox="0 0 101 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.184 4.396L14.0841 9.29427L18.8726 9.25204L15.0342 11.8659L17.0949 17.0724L12.2346 13.7661L7.41659 17.0724L9.36745 11.8532L5.57129 9.25204L10.3218 9.29427L12.184 4.396Z"
        fill={`var(${colors.badgeBlue.token})`}
      />
      <path
        d="M89.1373 0H11.8628C5.79062 0 0.862793 4.92361 0.862793 11C0.862793 17.0722 5.7864 22 11.8628 22H89.1373C95.2094 22 100.137 17.0764 100.137 11C100.137 4.92783 95.2137 0 89.1373 0ZM12.4582 20.104C7.42901 20.104 3.34993 16.025 3.34993 10.9958C3.34993 5.9666 7.42901 1.88752 12.4582 1.88752C17.4874 1.88752 21.5664 5.9666 21.5664 10.9958C21.5664 16.025 17.4874 20.104 12.4582 20.104Z"
        fill={`var(${colors.badgeBlue.token})`}
      />
      <path
        d="M30.9238 6.69287C31.9415 6.69287 32.5791 6.82377 33.0985 7.30938C33.525 7.71053 33.7235 8.22147 33.7235 8.87176C33.7235 9.18001 33.677 9.49671 33.487 9.80496C33.3096 10.1132 33.0732 10.2652 32.8958 10.3497C33.0394 10.3835 33.5208 10.5144 33.9135 10.9155C34.34 11.3631 34.4329 11.9078 34.4329 12.3934C34.4329 12.9593 34.2893 13.4111 33.9135 13.8123C33.2885 14.4752 32.3046 14.5808 31.7008 14.5808H29.7246V6.69287H30.9196H30.9238ZM30.9365 10.0879H31.3039C31.5995 10.0879 32.0006 10.0541 32.2835 9.77963C32.5538 9.51782 32.5918 9.15468 32.5918 8.8802C32.5918 8.64374 32.558 8.36082 32.3089 8.12435C32.0386 7.86254 31.6924 7.82876 31.3503 7.82876H30.9365V10.0879ZM30.9365 13.4449H31.6713C32.0133 13.4449 32.558 13.3858 32.8663 13.1366C33.1027 12.9466 33.2547 12.6172 33.2547 12.2625C33.2547 11.9416 33.1365 11.6376 32.9338 11.4349C32.6045 11.114 32.1188 11.0802 31.6924 11.0802H30.9365V13.4449Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M39.3778 9.59787H40.5348V14.5764H39.3778V14.057C38.8711 14.6482 38.2419 14.7326 37.8872 14.7326C36.3502 14.7326 35.4761 13.4574 35.4761 12.085C35.4761 10.4635 36.5866 9.47119 37.8999 9.47119C38.2672 9.47119 38.9049 9.56409 39.3778 10.1806V9.60209V9.59787ZM36.6584 12.1061C36.6584 12.9675 37.2031 13.6896 38.0434 13.6896C38.7782 13.6896 39.4496 13.1576 39.4496 12.1188C39.4496 11.08 38.774 10.51 38.0434 10.51C37.2031 10.51 36.6584 11.2194 36.6584 12.1061Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M44.1743 10.9114C44.056 10.7214 43.8322 10.4849 43.4649 10.4849C43.3002 10.4849 43.1819 10.5441 43.1102 10.6158C43.051 10.675 43.0046 10.7679 43.0046 10.8776C43.0046 11.0086 43.0637 11.0888 43.1693 11.1606C43.2411 11.207 43.3128 11.2324 43.5578 11.3633L44.1489 11.6588C44.4107 11.7897 44.6557 11.9206 44.8457 12.1191C45.1159 12.3894 45.213 12.6976 45.213 13.0396C45.213 13.4872 45.0484 13.88 44.7739 14.1755C44.3854 14.602 43.8998 14.7329 43.3804 14.7329C43.0595 14.7329 42.6583 14.6865 42.3036 14.412C42.0207 14.2009 41.7842 13.8546 41.666 13.4999L42.6119 13.0523C42.671 13.217 42.7766 13.4324 42.8821 13.5253C42.9539 13.597 43.1186 13.7153 43.3889 13.7153C43.5662 13.7153 43.7562 13.6562 43.8618 13.5506C43.9547 13.4577 44.0265 13.3015 44.0265 13.1621C44.0265 13.0228 43.98 12.9003 43.8745 12.7821C43.7309 12.6385 43.5662 12.5583 43.3762 12.4738L42.9497 12.2711C42.7259 12.1656 42.4514 12.0347 42.2656 11.8446C42.0038 11.5955 41.9109 11.3253 41.9109 11.0043C41.9109 10.6032 42.0629 10.2105 42.3247 9.94022C42.5485 9.70375 42.9286 9.46729 43.5409 9.46729C43.8956 9.46729 44.1785 9.53907 44.4614 9.72909C44.6261 9.83466 44.8499 10.0247 45.0272 10.3456L44.1658 10.9114H44.1743Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M46.835 6.79834C47.2615 6.79834 47.5782 7.11926 47.5782 7.54153C47.5782 7.96379 47.2573 8.28471 46.835 8.28471C46.4127 8.28471 46.0918 7.96379 46.0918 7.54153C46.0918 7.11926 46.4127 6.79834 46.835 6.79834ZM47.4135 9.60218V14.5807H46.2565V9.60218H47.4135Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M52.7339 11.1265C52.2019 10.6071 51.6825 10.51 51.3278 10.51C50.7831 10.51 50.4411 10.7338 50.2299 10.9491C50.0188 11.1603 49.8034 11.5403 49.8034 12.0977C49.8034 12.6551 50.0272 13.0689 50.251 13.2927C50.5128 13.5418 50.876 13.6938 51.3025 13.6938C51.7754 13.6938 52.3328 13.5038 52.7339 13.0562V14.3441C52.451 14.5215 51.9908 14.7326 51.2222 14.7326C50.3946 14.7326 49.8034 14.4961 49.3305 13.9979C48.9758 13.6305 48.6211 13.0393 48.6211 12.1315C48.6211 11.2236 49.0096 10.5691 49.3769 10.2059C49.7654 9.81745 50.3946 9.47119 51.3743 9.47119C51.7543 9.47119 52.2019 9.53031 52.7339 9.82589V11.1265Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M58.0203 6.69287V13.4449H60.3386V14.5808H56.8169V6.69287H58.0246H58.0203Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M61.8423 6.79834C62.2688 6.79834 62.5855 7.11926 62.5855 7.54153C62.5855 7.96379 62.2646 8.28471 61.8423 8.28471C61.42 8.28471 61.0991 7.96379 61.0991 7.54153C61.0991 7.11926 61.42 6.79834 61.8423 6.79834ZM62.4208 9.60218V14.5807H61.2638V9.60218H62.4208Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M65.0428 10.1807C65.5157 9.56415 66.1533 9.47125 66.5207 9.47125C67.8339 9.47125 68.9445 10.4636 68.9445 12.0851C68.9445 13.4574 68.0704 14.7327 66.5333 14.7327C66.1786 14.7327 65.5537 14.6482 65.0428 14.057V14.5764H63.8857V5.95801H65.0428V10.1807ZM64.971 12.1189C64.971 13.1576 65.6466 13.6897 66.3771 13.6897C67.2174 13.6897 67.7621 12.9676 67.7621 12.1062C67.7621 11.2448 67.2174 10.51 66.3771 10.51C65.6424 10.51 64.971 11.0294 64.971 12.1189Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M70.1523 9.59819H71.3094V10.0458C71.4403 9.89377 71.6303 9.72487 71.795 9.63197C72.0188 9.50107 72.2426 9.46729 72.5044 9.46729C72.7873 9.46729 73.0955 9.51373 73.4165 9.70375L72.9435 10.7552C72.6817 10.5905 72.4706 10.5778 72.3523 10.5778C72.1032 10.5778 71.8541 10.6116 71.6303 10.8481C71.3093 11.1901 71.3094 11.6631 71.3094 11.9967V14.5725H70.1523V9.59397V9.59819Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M77.4701 9.59787H78.6271V14.5764H77.4701V14.057C76.9634 14.6482 76.3342 14.7326 75.9795 14.7326C74.4424 14.7326 73.5684 13.4574 73.5684 12.085C73.5684 10.4635 74.6789 9.47119 75.9922 9.47119C76.3595 9.47119 76.9971 9.56409 77.4701 10.1806V9.60209V9.59787ZM74.7507 12.1061C74.7507 12.9675 75.2954 13.6896 76.1357 13.6896C76.8705 13.6896 77.5419 13.1576 77.5419 12.1188C77.5419 11.08 76.8662 10.51 76.1357 10.51C75.2954 10.51 74.7507 11.2194 74.7507 12.1061Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M80.0967 9.59819H81.2537V10.0458C81.3846 9.89377 81.5746 9.72487 81.7393 9.63197C81.9631 9.50107 82.1869 9.46729 82.4487 9.46729C82.7316 9.46729 83.0399 9.51373 83.3608 9.70375L82.8879 10.7552C82.626 10.5905 82.4149 10.5778 82.2967 10.5778C82.0475 10.5778 81.7984 10.6116 81.5746 10.8481C81.2537 11.1901 81.2537 11.6631 81.2537 11.9967V14.5725H80.0967V9.59397V9.59819Z"
        fill={`var(${colors.white.token})`}
      />
      <path
        d="M85.8518 13.7955L83.7236 9.59814H85.1171L86.5359 12.5075L87.9801 9.59814H89.2806L85.3198 17.2834H84.0192L85.8518 13.7955Z"
        fill={`var(${colors.white.token})`}
      />
    </svg>
  )
}

export default BasicLibraryFull
