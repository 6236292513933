import type { IconProps } from '../../../typings/props'

const AudioAndScorePreviewIcon = ({ className, title }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="31"
      height="32"
      viewBox="0 0 31 32"
      fill="currentColor"
    >
      {title ? <title>{title}</title> : null}

      <path d="M26.7386 4.37242H19.0671C17.1713 1.7285 14.076 0 10.5799 0C4.82417 0 0.139999 4.68515 0.139999 10.4421C0.139999 13.339 1.32525 15.963 3.23815 17.8564V27.8778C3.23815 30.1521 5.08567 32 7.35954 32H26.7386C29.0125 32 30.86 30.1521 30.86 27.8778V8.49467C30.86 6.22033 29.0125 4.37242 26.7386 4.37242ZM1.56117 10.4421C1.56117 5.46695 5.60582 1.42146 10.5799 1.42146C15.554 1.42146 19.5987 5.46695 19.5987 10.4421C19.5987 15.4172 15.554 19.4627 10.5799 19.4627C5.60582 19.4627 1.56117 15.4172 1.56117 10.4421ZM29.4388 27.8778C29.4388 29.3674 28.228 30.5785 26.7386 30.5785H7.35954C5.87015 30.5785 4.65932 29.3674 4.65932 27.8778V19.0362C6.34198 20.2018 8.38278 20.8841 10.5799 20.8841C16.3356 20.8841 21.0198 16.199 21.0198 10.4421C21.0198 8.77328 20.6247 7.19545 19.9255 5.79389H26.7386C28.228 5.79389 29.4388 7.00497 29.4388 8.49467V27.8778Z" />

      <path d="M27.3923 17.6744L24.3965 16.1876C24.1634 16.071 23.8877 16.0852 23.666 16.2217C23.4443 16.3582 23.3079 16.597 23.3079 16.8557V22.4477C22.8673 22.1947 22.3557 22.0525 21.8072 22.0525C20.1529 22.0525 18.8113 23.383 18.8113 25.0177C18.8113 26.6524 20.1558 27.9829 21.8072 27.9829H21.8128C23.4671 27.9829 24.8087 26.6524 24.8087 25.0177V18.0554L26.7244 19.0021C27.0967 19.1869 27.543 19.0362 27.7306 18.6666C27.9153 18.297 27.7647 17.8564 27.3923 17.6716V17.6744ZM21.8128 26.5046C20.98 26.5046 20.3121 25.8422 20.3121 25.0177C20.3121 24.1933 20.98 23.5309 21.8128 23.5309C22.6456 23.5309 23.3136 24.1933 23.3136 25.0177C23.3136 25.8422 22.6456 26.5046 21.8128 26.5046Z" />

      <path d="M15.8212 23.3092H8.51353C8.12129 23.3092 7.80295 23.6276 7.80295 24.0199C7.80295 24.4123 8.12129 24.7307 8.51353 24.7307H15.8212C16.2134 24.7307 16.5318 24.4123 16.5318 24.0199C16.5318 23.6276 16.2134 23.3092 15.8212 23.3092Z" />

      <path d="M15.8212 26.1777H8.51353C8.12129 26.1777 7.80295 26.4961 7.80295 26.8885C7.80295 27.2808 8.12129 27.5992 8.51353 27.5992H15.8212C16.2134 27.5992 16.5318 27.2808 16.5318 26.8885C16.5318 26.4961 16.2134 26.1777 15.8212 26.1777Z" />

      <path d="M8.15824 14.8316C8.26909 14.8941 8.39131 14.9254 8.51353 14.9254C8.63575 14.9254 8.75797 14.8941 8.86883 14.8316L15.4034 11.059C15.6222 10.9311 15.7587 10.698 15.7587 10.4421C15.7587 10.1862 15.6222 9.95311 15.4034 9.82518L8.86883 6.05261C8.64997 5.92468 8.3771 5.92468 8.15824 6.05261C7.93938 6.18055 7.80295 6.41367 7.80295 6.66953V14.2147C7.80295 14.4677 7.93938 14.7036 8.15824 14.8316ZM9.22412 7.90052L13.6269 10.4421L9.22412 12.9837V7.90052Z" />
    </svg>
  )
}

export default AudioAndScorePreviewIcon
