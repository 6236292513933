import styles from '../../Footer/FooterSocialMedia.module.scss'

const YouTube = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M23.9004 23.9002C29.3678 18.4327 29.3678 9.56824 23.9004 4.10077C18.4329 -1.36669 9.56841 -1.36669 4.10095 4.10077C-1.36651 9.56823 -1.36651 18.4327 4.10095 23.9002C9.56841 29.3677 18.4329 29.3677 23.9004 23.9002Z"
          className={styles.background}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.85972 8.07709C10.5359 7.94322 12.5141 7.87598 14.0227 7.87598C15.5314 7.87598 17.476 7.94322 19.1858 8.07709C21.1639 8.24459 22.1695 8.5802 22.4379 10.5584C22.5717 11.4637 22.639 12.704 22.639 13.978C22.639 15.2519 22.5717 16.5258 22.4379 17.3976C22.1695 19.4093 21.1639 19.7107 19.1858 19.8788C17.476 20.0463 15.5314 20.0799 14.0227 20.0799C12.5141 20.0799 10.5359 20.0463 8.85972 19.8788C6.88155 19.7113 5.84235 19.4093 5.57399 17.3976C5.47312 16.5258 5.40649 15.2519 5.40649 13.978C5.40649 12.704 5.47374 11.4637 5.57399 10.5584C5.84235 8.5802 6.88155 8.24521 8.85972 8.07709Z"
          className={styles.icon}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2793 11.3965L16.7717 13.978L12.2793 16.5931V11.3965Z"
          className={styles.background}
        />
      </g>
    </svg>
  )
}

export default YouTube
