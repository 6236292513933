import { colors } from '../../../../constants/colors'

const MintsSimple = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M22.76 45.51C10.21 45.51 0 35.3 0 22.75C0 10.2 10.21 0 22.76 0C35.31 0 45.51 10.21 45.51 22.75C45.51 35.29 35.3 45.5 22.76 45.5V45.51ZM22.76 3.5C12.14 3.5 3.5 12.14 3.5 22.75C3.5 33.36 12.14 42 22.76 42C33.38 42 42.01 33.36 42.01 22.75C42.01 12.14 33.37 3.5 22.76 3.5Z"
          fill={`var(${colors.badgeBlack.token})`}
        />
        <path
          d="M32.16 20.89C30.62 19.82 29.3 18.48 28.25 16.93C27.4 15.66 26.74 14.28 26.29 12.83C25.83 11.41 25.58 9.93 25.53 8.43V8.25L23.75 8.22V26.39C21.34 23.94 17.4 23.9 14.95 26.31C12.5 28.72 12.46 32.66 14.87 35.11C17.28 37.56 21.22 37.6 23.67 35.19C24.83 34.05 25.5 32.51 25.53 30.88V15.71C25.89 16.46 26.3 17.18 26.75 17.87C27.92 19.62 29.41 21.13 31.14 22.33L31.87 22.84L32.89 21.38L32.16 20.88V20.89ZM19.3 35.2C16.84 35.2 14.85 33.21 14.86 30.75C14.87 28.29 16.85 26.3 19.31 26.31C21.77 26.31 23.75 28.3 23.75 30.76C23.75 33.22 21.76 35.21 19.3 35.21"
          fill={`var(${colors.badgeBlack.token})`}
        />
      </g>
    </svg>
  )
}

export default MintsSimple
