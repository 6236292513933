const MusicNoteIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.1499 5.99999C17.1499 5.99967 17.1499 5.99935 17.1499 5.99902H16.0499C16.0499 6.33583 16.0669 6.66862 16.1 6.99653L16.1 17.2049C15.3515 16.4018 14.2844 15.8996 13.1 15.8996C10.8356 15.8996 9 17.7352 9 19.9996C9 22.264 10.8356 24.0996 13.1 24.0996C15.3644 24.0996 17.2 22.264 17.2 19.9996C17.2 19.9928 17.2 19.986 17.2 19.9792L17.2 10.608C18.0858 12.2598 19.4335 13.6139 21.0663 14.4844L21.5838 13.5137C19.2098 12.2481 17.5077 9.81347 17.2 6.93925V5.99999L17.1499 5.99999ZM16.1 19.9822V20H16.1C16.0998 21.6567 14.7567 22.9996 13.1 22.9996C11.4431 22.9996 10.1 21.6565 10.1 19.9996C10.1 18.3427 11.4431 16.9996 13.1 16.9996C14.751 16.9996 16.0906 18.3333 16.1 19.9822Z" />
      <path
        d="M24.8996 5.10044C22.2551 2.45631 18.7396 1 15 1C11.2604 1 7.74489 2.45631 5.10044 5.10044C2.45631 7.74489 1 11.2604 1 15C1 18.7396 2.45631 22.2551 5.10044 24.8996C7.74489 27.5437 11.2604 29 15 29C18.7396 29 22.2551 27.5437 24.8996 24.8996C27.5437 22.2551 29 18.7396 29 15C29 11.2604 27.5437 7.74489 24.8996 5.10044ZM15 27.9061C7.88333 27.9061 2.09387 22.1167 2.09387 15C2.09387 7.88333 7.88333 2.09387 15 2.09387C22.1167 2.09387 27.9061 7.88333 27.9061 15C27.9061 22.1167 22.1167 27.9061 15 27.9061Z"
        strokeWidth="0.3"
      />
    </svg>
  )
}

export default MusicNoteIcon
