import type { IconProps } from '../../../typings/props'

const ExclamationIcon = ({ className, title }: IconProps) => {
  return (
    <svg
      className={className}
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      {title ? <title>{title}</title> : null}

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.69521 15.5777C3.66609 15.5777 0.390381 12.302 0.390381 8.27287C0.390381 4.24375 3.66609 0.968048 7.69521 0.968048C11.7243 0.968048 15 4.24375 15 8.27287C15 12.302 11.7243 15.5777 7.69521 15.5777ZM7.69521 2.37192C4.4429 2.37192 1.79425 5.02056 1.79425 8.27287C1.79425 11.5252 4.4429 14.1738 7.69521 14.1738C10.9475 14.1738 13.5962 11.5252 13.5962 8.27287C13.5962 5.02056 10.9475 2.37192 7.69521 2.37192ZM7.62485 9.79239C7.23644 9.79239 6.92291 9.47886 6.92291 9.09046V5.14557C6.92291 4.75717 7.23644 4.44363 7.62485 4.44363C8.01325 4.44363 8.32679 4.75717 8.32679 5.14557V9.09046C8.32679 9.47886 8.01325 9.79239 7.62485 9.79239ZM8.39699 10.9071C8.39699 11.3336 8.05129 11.6793 7.62486 11.6793C7.19842 11.6793 6.85273 11.3336 6.85273 10.9071C6.85273 10.4807 7.19842 10.135 7.62486 10.135C8.05129 10.135 8.39699 10.4807 8.39699 10.9071Z"
      />
    </svg>
  )
}

export default ExclamationIcon
