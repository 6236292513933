import Facebook from '../components/Icons/SocialMedia/Facebook'
import Instagram from '../components/Icons/SocialMedia/Instagram'
import Linkedin from '../components/Icons/SocialMedia/Linkedin'
import X from '../components/Icons/SocialMedia/X'
import Youtube from '../components/Icons/SocialMedia/Youtube'

export const socialIcons = [
  {
    icon: <Facebook />,
    label: 'Facebook',
    url: 'https://www.facebook.com/jwpepper',
  },
  {
    icon: <Instagram />,
    label: 'Instagram',
    url: 'https://www.instagram.com/jwpeppermusic/',
  },
  {
    icon: <Linkedin />,
    label: 'LinkedIn',
    url: 'https://www.linkedin.com/company/jwpepper',
  },
  {
    icon: <X />,
    label: 'X',
    url: 'https://x.com/jwpepper',
  },
  {
    icon: <Youtube />,
    label: 'Youtube',
    url: 'https://www.youtube.com/user/jwpepper1876',
  },
]
