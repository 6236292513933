import type { IconProps } from '../../../typings/props'

const StateIcon = ({ className, title }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="30"
      height="29"
      fill="currentColor"
      viewBox="0 0 30 29"
    >
      {title ? <title>{title}</title> : null}
      <path d="m29.927 24.243-2.81-7.45a.99.99 0 0 0-1.167-.6l-8.27 2.4-4.01-.853v1.989l3.852.852c.158.032.315.032.473 0l7.64-2.241 2.114 5.682-5.997 2.083H4.517l-2.305-4.04 4.293-3.852 1.894.41v-1.988l-1.989-.442c-.284-.063-.6.032-.852.221L.318 21.15c-.347.284-.41.82-.19 1.2l3 5.208c.189.284.505.474.852.474h17.93c.094 0 .22 0 .315-.032l7.102-2.462c.253-.095.442-.284.568-.505a.987.987 0 0 0 .032-.79Z" />
      <path d="M11.02 21.907a.983.983 0 0 0 .978-.979v-8.996h9.343a.983.983 0 0 0 .979-.979V2.904a.983.983 0 0 0-.979-.978h-9.343V.979A.983.983 0 0 0 11.019 0a.983.983 0 0 0-.979.979v19.95c.032.536.442.978.98.978Zm9.374-18.024v6.092h-8.396V3.883h8.396Z" />
    </svg>
  )
}

export default StateIcon
