import type { IconProps } from '../../../typings/props'

const HelpIcon = ({ className, title }: IconProps) => {
  return (
    <svg
      className={className}
      fill="currentColor"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
    >
      {title ? <title>{title}</title> : null}
      <path d="m13.00031,5.57129c-2.56281,0-4.64282,2.08002-4.7171,4.64282,0,.63147.52002,1.11426,1.11426,1.11426.63147,0,1.11432-.51996,1.11432-1.11426,0-1.3371,1.11432-2.45142,2.45142-2.45142s2.45142,1.11432,2.45142,2.45142c.03717.74286-.44574,1.37427-1.15143,1.59717-1.59711.48285-2.71136,1.96863-2.71136,3.67712,0,.63147.51996,1.11432,1.11426,1.11432.59424,0,1.1142-.52002,1.1142-1.11432-.03705-.7428.4458-1.37433,1.15155-1.59711,1.59711-.48291,2.71136-1.96863,2.71136-3.67719,0-2.56281-2.08002-4.64282-4.64288-4.64282Z" />
      <path d="m12.66528,17.56842c-.73846,0-1.33716.59857-1.33716,1.3371,0,.73846.59869,1.3371,1.33716,1.3371.73853,0,1.3371-.59863,1.3371-1.3371,0-.73853-.59857-1.3371-1.3371-1.3371Z" />
      <path d="m13,0C5.82031,0,0,5.82031,0,13s5.82031,13,13,13,13-5.82031,13-13S20.17969,0,13,0Zm0,24.5c-6.35126,0-11.5-5.14868-11.5-11.5S6.64874,1.5,13,1.5s11.5,5.14874,11.5,11.5-5.14868,11.5-11.5,11.5Z" />
    </svg>
  )
}

export default HelpIcon
