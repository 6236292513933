import type { IconProps } from '../../../typings/props'

const Trashcan = ({ className, title }: IconProps) => {
  return (
    <svg
      className={className}
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      {title ? <title>{title}</title> : null}
      <path
        d="M6.42721 14.0724C6.80384 14.0724 7.112 13.7643 7.112 13.3876V7.64035C7.112 7.25883 6.80384 6.95557 6.42721 6.95557C6.05058 6.95557 5.74243 7.26372 5.74243 7.64035V13.3876C5.74243 13.7643 6.05058 14.0724 6.42721 14.0724Z"
        fill="currentColor"
      />
      <path
        d="M10.0468 14.0724C10.4235 14.0724 10.7316 13.7643 10.7316 13.3876V7.64035C10.7316 7.25883 10.4235 6.95557 10.0468 6.95557C9.67021 6.95557 9.36206 7.26372 9.36206 7.64035V13.3876C9.36206 13.7643 9.67021 14.0724 10.0468 14.0724Z"
        fill="currentColor"
      />
      <path
        d="M15.794 2.39674H11.3185V1.66304C11.3185 0.74837 10.5946 0 9.67989 0H6.79891C5.88424 0 5.16033 0.74837 5.16033 1.66304V2.39674H0.684783C0.308152 2.39674 0 2.70489 0 3.08152C0 3.45815 0.308152 3.7663 0.684783 3.7663H2.12283V16.337C2.12283 17.2516 2.85652 18 3.7712 18H12.6978C13.6125 18 14.3462 17.2516 14.3462 16.337V3.7663H15.7891C16.1658 3.7663 16.4739 3.45815 16.4739 3.08152C16.4739 2.70489 16.1755 2.39674 15.794 2.39674ZM6.52011 1.66304C6.525 1.50163 6.63261 1.36957 6.79402 1.36957H9.675C9.83641 1.36957 9.94402 1.50163 9.94402 1.66304V2.39674H6.52011V1.66304ZM12.9815 16.337C12.9815 16.4984 12.8641 16.6304 12.7027 16.6304H3.7712C3.60978 16.6304 3.49239 16.4984 3.49239 16.337V3.7663H12.9815V16.337Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Trashcan
