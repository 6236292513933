const CircledPlusIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 0.75C8.07164 0.75 6.18657 1.32183 4.58319 2.39317C2.97982 3.46451 1.73013 4.98726 0.992179 6.76884C0.254225 8.55042 0.061142 10.5108 0.437348 12.4021C0.813554 14.2934 1.74215 16.0307 3.10571 17.3943C4.46928 18.7579 6.20656 19.6865 8.09787 20.0627C9.98919 20.4389 11.9496 20.2458 13.7312 19.5078C15.5127 18.7699 17.0355 17.5202 18.1068 15.9168C19.1782 14.3134 19.75 12.4284 19.75 10.5C19.7473 7.91498 18.7192 5.43661 16.8913 3.60872C15.0634 1.78084 12.585 0.75273 10 0.75ZM10 18.75C8.36831 18.75 6.77326 18.2661 5.41655 17.3596C4.05984 16.4531 3.00242 15.1646 2.378 13.6571C1.75358 12.1496 1.5902 10.4908 1.90853 8.8905C2.22685 7.29016 3.01259 5.82015 4.16637 4.66637C5.32016 3.51259 6.79017 2.72685 8.39051 2.40852C9.99085 2.09019 11.6497 2.25357 13.1571 2.87799C14.6646 3.50242 15.9531 4.55984 16.8596 5.91655C17.7661 7.27325 18.25 8.8683 18.25 10.5C18.2475 12.6873 17.3775 14.7843 15.8309 16.3309C14.2843 17.8775 12.1873 18.7475 10 18.75ZM14.5 10.5C14.5 10.6989 14.421 10.8897 14.2803 11.0303C14.1397 11.171 13.9489 11.25 13.75 11.25H10.75V14.25C10.75 14.4489 10.671 14.6397 10.5303 14.7803C10.3897 14.921 10.1989 15 10 15C9.80109 15 9.61033 14.921 9.46967 14.7803C9.32902 14.6397 9.25 14.4489 9.25 14.25V11.25H6.25C6.05109 11.25 5.86033 11.171 5.71967 11.0303C5.57902 10.8897 5.5 10.6989 5.5 10.5C5.5 10.3011 5.57902 10.1103 5.71967 9.96967C5.86033 9.82902 6.05109 9.75 6.25 9.75H9.25V6.75C9.25 6.55109 9.32902 6.36032 9.46967 6.21967C9.61033 6.07902 9.80109 6 10 6C10.1989 6 10.3897 6.07902 10.5303 6.21967C10.671 6.36032 10.75 6.55109 10.75 6.75V9.75H13.75C13.9489 9.75 14.1397 9.82902 14.2803 9.96967C14.421 10.1103 14.5 10.3011 14.5 10.5Z" />
    </svg>
  )
}

export default CircledPlusIcon
