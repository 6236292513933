import { colors } from '../../../../constants/colors'

const LargePrintEditionFull = () => {
  return (
    <svg
      width="124"
      height="23"
      viewBox="0 0 124 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_9422_9406)">
        <path
          d="M109.908 0.5H11.5C5.42783 0.5 0.5 5.42361 0.5 11.5C0.5 17.5722 5.42361 22.5 11.5 22.5H109.908C115.981 22.5 120.908 17.5764 120.908 11.5C120.908 5.42361 115.985 0.5 109.908 0.5ZM11.6562 20.7223C6.55106 20.7223 2.41286 16.5841 2.41286 11.4789C2.41286 6.37371 6.55106 2.23551 11.6562 2.23551C16.7614 2.23551 20.8996 6.37371 20.8996 11.4789C20.8996 16.5841 16.7614 20.7223 11.6562 20.7223Z"
          fill={`var(${colors.badgeBlack.token})`}
        />
        <path
          d="M28.669 7.21375V13.7726H30.921V14.876H27.5V7.21375H28.6731H28.669Z"
          fill={`var(${colors.white.token})`}
        />
        <path
          d="M35.3551 10.0399H36.479V14.876H35.3551V14.3715C34.8628 14.9458 34.2517 15.0278 33.9071 15.0278C32.414 15.0278 31.5649 13.789 31.5649 12.4559C31.5649 10.8808 32.6437 9.91687 33.9194 9.91687C34.2763 9.91687 34.8957 10.0071 35.3551 10.606V10.044V10.0399ZM32.7135 12.4764C32.7135 13.3132 33.2426 14.0146 34.0589 14.0146C34.7726 14.0146 35.4248 13.4978 35.4248 12.4887C35.4248 11.4797 34.7685 10.9259 34.0589 10.9259C33.2426 10.9259 32.7135 11.615 32.7135 12.4764Z"
          fill={`var(${colors.white.token})`}
        />
        <path
          d="M37.9064 10.0399H39.0303V10.4747C39.1574 10.327 39.342 10.1629 39.502 10.0727C39.7194 9.94553 39.9368 9.91272 40.1911 9.91272C40.4659 9.91272 40.7654 9.95784 41.0771 10.1424L40.6177 11.1638C40.3634 11.0038 40.1583 10.9915 40.0434 10.9915C39.8014 10.9915 39.5594 11.0243 39.342 11.254C39.0303 11.5863 39.0303 12.0457 39.0303 12.3697V14.8719H37.9064V10.0358V10.0399Z"
          fill={`var(${colors.white.token})`}
        />
        <path
          d="M44.9001 10.0399H46.024V14.4863C46.024 15.6225 45.8968 16.4388 45.2323 17.0007C44.9206 17.2551 44.4161 17.5176 43.6244 17.5176C43.0624 17.5176 42.5005 17.3781 42.0411 16.9187C41.6842 16.5618 41.4094 15.9999 41.3274 15.4133H42.4185C42.4636 15.6758 42.603 15.9876 42.7753 16.1722C42.9148 16.3198 43.1896 16.5044 43.649 16.5044C44.1084 16.5044 44.3956 16.3198 44.535 16.1845C44.9042 15.8276 44.9042 15.3231 44.9042 14.9662V14.3345C44.3874 14.9908 43.7557 15.0236 43.4357 15.0236C42.8163 15.0236 42.2995 14.8514 41.8401 14.3673C41.4053 13.9079 41.1182 13.2516 41.1182 12.4477C41.1182 11.5617 41.4504 10.9546 41.8319 10.5772C42.3159 10.085 42.8902 9.91272 43.4726 9.91272C43.9567 9.91272 44.494 10.0727 44.9083 10.569V10.0399H44.9001ZM42.7507 11.2458C42.4636 11.5083 42.2585 11.9595 42.2585 12.4764C42.2585 12.9604 42.4431 13.428 42.7425 13.6946C42.9476 13.8792 43.2593 14.0146 43.6039 14.0146C43.9484 14.0146 44.2684 13.912 44.5473 13.6249C44.8796 13.268 44.9739 12.8538 44.9739 12.4887C44.9739 11.9472 44.8016 11.5453 44.5145 11.2704C44.2397 10.9956 43.9279 10.9259 43.608 10.9259C43.288 10.9259 43.0091 11.0079 42.7589 11.2458H42.7507Z"
          fill={`var(${colors.white.token})`}
        />
        <path
          d="M51.7789 13.7726C51.5738 14.1418 51.2743 14.4617 50.9626 14.6586C50.5729 14.9129 50.0561 15.0278 49.5393 15.0278C48.8953 15.0278 48.3661 14.8678 47.9067 14.4084C47.4473 13.949 47.193 13.2845 47.193 12.5585C47.193 11.8324 47.4555 11.09 47.9641 10.5732C48.3661 10.1712 48.8953 9.91687 49.5844 9.91687C50.3555 9.91687 50.8478 10.2491 51.1349 10.5486C51.7543 11.1925 51.8117 12.0868 51.8117 12.5913V12.7307H48.3415C48.3661 13.0753 48.5138 13.465 48.7558 13.707C49.0183 13.9695 49.3424 14.0187 49.6172 14.0187C49.9289 14.0187 50.1586 13.949 50.3883 13.7767C50.5852 13.6291 50.7329 13.4322 50.8354 13.2476L51.7789 13.7767V13.7726ZM50.6632 11.8078C50.618 11.5453 50.4909 11.3156 50.3186 11.1515C50.1709 11.0121 49.9289 10.8767 49.5598 10.8767C49.1701 10.8767 48.9158 11.0367 48.7681 11.1884C48.6081 11.3361 48.481 11.5658 48.4236 11.8078H50.6632Z"
          fill={`var(${colors.white.token})`}
        />
        <path
          d="M57.5543 7.21375C58.0261 7.21375 58.6783 7.25887 59.232 7.62803C59.876 8.05463 60.1836 8.78886 60.1836 9.49028C60.1836 9.93738 60.0688 10.6265 59.4371 11.1679C58.83 11.6848 58.1286 11.7668 57.5667 11.7668H57.0375V14.8801H55.8644V7.21785H57.5543V7.21375ZM57.0375 10.6839H57.5667C58.5757 10.6839 59.0597 10.1917 59.0597 9.47797C59.0597 9.05138 58.8875 8.29664 57.5543 8.29664H57.0375V10.6839Z"
          fill={`var(${colors.white.token})`}
        />
        <path
          d="M61.1722 10.0399H62.2961V10.4747C62.4233 10.327 62.6079 10.1629 62.7679 10.0727C62.9853 9.94553 63.2027 9.91272 63.457 9.91272C63.7318 9.91272 64.0312 9.95784 64.343 10.1424L63.8836 11.1638C63.6293 11.0038 63.4242 10.9915 63.3093 10.9915C63.0673 10.9915 62.8253 11.0243 62.6079 11.254C62.2962 11.5863 62.2961 12.0457 62.2961 12.3697V14.8719H61.1722V10.0358V10.0399Z"
          fill={`var(${colors.white.token})`}
        />
        <path
          d="M65.5488 7.31628C65.9631 7.31628 66.2708 7.62803 66.2708 8.03821C66.2708 8.4484 65.959 8.76014 65.5488 8.76014C65.1386 8.76014 64.8269 8.4484 64.8269 8.03821C64.8269 7.62803 65.1386 7.31628 65.5488 7.31628ZM66.1108 10.0399V14.876H64.9869V10.0399H66.1108Z"
          fill={`var(${colors.white.token})`}
        />
        <path
          d="M67.526 10.0399H68.6499V10.487C69.1339 9.94553 69.6836 9.91272 69.9584 9.91272C70.8526 9.91272 71.2341 10.3598 71.3818 10.569C71.5663 10.8315 71.6812 11.1679 71.6812 11.9144V14.876H70.5573V12.1893C70.5573 10.9587 70.0979 10.9259 69.6713 10.9259C69.1668 10.9259 68.6499 10.9956 68.6499 12.5584V14.8801H67.526V10.044V10.0399Z"
          fill={`var(${colors.white.token})`}
        />
        <path
          d="M74.2653 11.0736V14.876H73.1414V11.0736H72.6697V10.0399H73.1414V8.27203H74.2653V10.0399H75.1267V11.0736H74.2653Z"
          fill={`var(${colors.white.token})`}
        />
        <path
          d="M82.9941 8.31715H79.9382V10.2573H82.8997V11.3607H79.9382V13.7726H82.9941V14.876H78.7692V7.21375H82.9941V8.31715Z"
          fill={`var(${colors.white.token})`}
        />
        <path
          d="M87.8425 6.5H88.9664V14.8719H87.8425V14.3674C87.3503 14.9416 86.7391 15.0237 86.3945 15.0237C84.9015 15.0237 84.0524 13.7849 84.0524 12.4518C84.0524 10.8767 85.1312 9.91275 86.4068 9.91275C86.7637 9.91275 87.3831 10.003 87.8425 10.6019V6.5ZM85.2009 12.4723C85.2009 13.3091 85.73 14.0105 86.5463 14.0105C87.26 14.0105 87.9122 13.4937 87.9122 12.4846C87.9122 11.4756 87.2559 10.9218 86.5463 10.9218C85.73 10.9218 85.2009 11.6109 85.2009 12.4723Z"
          fill={`var(${colors.white.token})`}
        />
        <path
          d="M90.9558 7.31628C91.3701 7.31628 91.6777 7.62803 91.6777 8.03821C91.6777 8.4484 91.366 8.76014 90.9558 8.76014C90.5456 8.76014 90.2339 8.4484 90.2339 8.03821C90.2339 7.62803 90.5456 7.31628 90.9558 7.31628ZM91.5178 10.0399V14.876H90.3939V10.0399H91.5178Z"
          fill={`var(${colors.white.token})`}
        />
        <path
          d="M94.102 11.0736V14.876H92.9781V11.0736H92.5063V10.0399H92.9781V8.27203H94.102V10.0399H94.9634V11.0736H94.102Z"
          fill={`var(${colors.white.token})`}
        />
        <path
          d="M96.2842 7.31628C96.6985 7.31628 97.0061 7.62803 97.0061 8.03821C97.0061 8.4484 96.6944 8.76014 96.2842 8.76014C95.874 8.76014 95.5623 8.4484 95.5623 8.03821C95.5623 7.62803 95.874 7.31628 96.2842 7.31628ZM96.8461 10.0399V14.876H95.7222V10.0399H96.8461Z"
          fill={`var(${colors.white.token})`}
        />
        <path
          d="M103.183 12.4641C103.183 13.9449 102.06 15.0236 100.599 15.0236C99.139 15.0236 98.0151 13.9449 98.0151 12.4641C98.0151 10.9833 99.139 9.91272 100.599 9.91272C102.06 9.91272 103.183 10.971 103.183 12.4641ZM102.035 12.4764C102.035 11.373 101.313 10.9259 100.599 10.9259C99.8856 10.9259 99.1637 11.373 99.1637 12.4764C99.1637 13.4075 99.7133 14.0146 100.599 14.0146C101.485 14.0146 102.035 13.4075 102.035 12.4764Z"
          fill={`var(${colors.white.token})`}
        />
        <path
          d="M104.344 10.0399H105.468V10.487C105.952 9.94553 106.502 9.91272 106.777 9.91272C107.671 9.91272 108.052 10.3598 108.2 10.569C108.385 10.8315 108.499 11.1679 108.499 11.9144V14.876H107.376V12.1893C107.376 10.9587 106.916 10.9259 106.49 10.9259C105.985 10.9259 105.468 10.9956 105.468 12.5584V14.8801H104.344V10.044V10.0399Z"
          fill={`var(${colors.white.token})`}
        />
        <path
          d="M11.2118 5.5V14.9178H14.5V16.5H9.5V5.5H11.2118Z"
          fill={`var(${colors.badgeBlack.token})`}
        />
      </g>
      <defs>
        <clipPath>
          <rect
            width="126"
            height="22"
            fill={`var(${colors.white.token})`}
            transform="translate(-2.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LargePrintEditionFull
