const SavedIcon = () => {
  return (
    <svg
      width="23"
      height="21"
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.8389 4.96693V9.63996C20.7302 9.91105 23 12.3328 23 15.2734C23 18.3948 20.4442 20.9327 17.3007 20.9327C14.3392 20.9327 11.9004 18.6788 11.6274 15.8078H2.19704C0.985417 15.8078 0 14.8293 0 13.6262V2.98928C0 1.78617 0.985417 0.807678 2.19704 0.807678H8.42414C9.22236 0.807678 9.91137 1.20011 10.317 1.8817L10.8526 2.78532H15.6419C16.8535 2.78532 17.8389 3.76382 17.8389 4.96693ZM8.42414 1.87912H2.19704C1.58083 1.87912 1.07902 2.3774 1.07902 2.99187V6.15455H16.7625V4.96693C16.7625 4.35505 16.2607 3.85676 15.6445 3.85676H10.5458C10.3534 3.85676 10.1766 3.75607 10.0804 3.59342L9.38876 2.42646C9.17816 2.07275 8.83495 1.87912 8.42414 1.87912ZM1.07902 7.22341V13.6262C1.07902 14.2407 1.58083 14.739 2.19704 14.739H11.6274C11.8848 12.0436 14.048 9.89297 16.7625 9.63996V7.22341H1.07902ZM14.4287 15.8821C14.4642 15.9677 14.5162 16.0455 14.5817 16.1111L15.991 17.5198C16.0565 17.5855 16.1343 17.6376 16.2199 17.6731C16.3056 17.7087 16.3974 17.727 16.4902 17.727C16.5829 17.727 16.6747 17.7087 16.7604 17.6731C16.846 17.6376 16.9238 17.5855 16.9893 17.5198L20.2776 14.2314C20.41 14.099 20.4844 13.9195 20.4844 13.7323C20.4844 13.5451 20.41 13.3655 20.2776 13.2332C20.1453 13.1008 19.9657 13.0264 19.7785 13.0264C19.5913 13.0264 19.4118 13.1008 19.2794 13.2332L16.4896 16.0236L15.58 15.1128C15.5144 15.0473 15.4366 14.9953 15.351 14.9598C15.2654 14.9243 15.1736 14.9061 15.0809 14.9061C14.9882 14.9061 14.8964 14.9243 14.8107 14.9598C14.7251 14.9953 14.6473 15.0473 14.5817 15.1128C14.5162 15.1784 14.4642 15.2562 14.4287 15.3418C14.3933 15.4275 14.375 15.5192 14.375 15.6119C14.375 15.7046 14.3933 15.7964 14.4287 15.8821Z"
        fill="#4A6740"
      />
    </svg>
  )
}

export default SavedIcon
