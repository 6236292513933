import type { IconProps } from '../../../typings/props'

const CartIcon = ({ className, title }: IconProps) => {
  return (
    <svg
      className={className}
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      {title ? <title>{title}</title> : null}

      <g>
        <path d="M19.1636 21.9949C20.0695 21.9949 20.8038 21.2573 20.8038 20.3475C20.8038 19.4377 20.0695 18.7002 19.1636 18.7002C18.2578 18.7002 17.5234 19.4377 17.5234 20.3475C17.5234 21.2573 18.2578 21.9949 19.1636 21.9949Z" />

        <path d="M11.2276 21.9949C12.1335 21.9949 12.8678 21.2573 12.8678 20.3475C12.8678 19.4377 12.1335 18.7002 11.2276 18.7002C10.3217 18.7002 9.5874 19.4377 9.5874 20.3475C9.5874 21.2573 10.3217 21.9949 11.2276 21.9949Z" />

        <path d="M9.73537 13.8855H20.8517C21.1427 13.8855 21.4072 13.6995 21.5025 13.4179L24.6135 4.20338C24.7352 3.84203 24.5448 3.45411 24.185 3.33188C24.1162 3.30531 24.0421 3.29469 23.9628 3.29469H7.00523L6.12693 0.478261C6.03699 0.196618 5.77244 0 5.47085 0H0.682534C0.306876 0 0 0.308213 0 0.685507C0 1.0628 0.306876 1.37101 0.682534 1.37101H4.96821L8.61897 13.0831L7.82533 14.0928C7.14809 14.9589 7.29623 16.213 8.15866 16.8932C8.50786 17.1696 8.94172 17.3184 9.38616 17.3184H22.312C22.6876 17.3184 22.9945 17.0101 22.9945 16.6328C22.9945 16.2556 22.6876 15.9473 22.312 15.9473H9.38616C9.04754 15.9473 8.76712 15.671 8.76712 15.3256C8.76712 15.1874 8.81474 15.0493 8.89939 14.943L9.73007 13.8855H9.73537ZM7.4338 4.67101H23.0051L20.3596 12.5092H9.87822L7.4338 4.67101Z" />
      </g>
    </svg>
  )
}

export default CartIcon
